.testimonialCardContainer {
    width: 100%;
    height: 67.5rem;
    display: grid;
    place-items: center;
}

.testimonial-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    /* 20px */
    padding: 0; /* default padding if needed */
}

.testimonial-container .testimonial-head {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    /* 10px */
}

.testimonial-container .testimonial-head .testimonial-head-h1 {
    font-family: Inter;
    font-size: 3.185625rem;
    /* 50.97px */
    font-weight: 900;
}

.testimonial-container .testimonial-head .testimonial-head-p {
    font-family: Inter;
    font-size: 1.39875rem;
    /* 22.38px */
    font-weight: 700;
    color: rgba(100, 116, 139, 1);
}

.testimonial-container .testimonial-card {
    width: 90%;
    height: 32rem;
    border: 0.083125rem solid rgba(203, 213, 225, 1);
    /* 1.33px */
    border-radius: 1.25rem;
    /* 20px */
    display: flex;
    gap: 1.25rem;
    /* 20px */
    overflow: hidden;
}

.testimonial-container .testimonial-card .testimonial-image {
    width: auto;
    height: 512px;
}

.testimonial-container .testimonial-card .testimonial-content {
    padding: 0.625rem;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
}

.testimonial-container .testimonial-card .testimonial-content .testimonial-company {
    display: flex;
    align-items: center;
    gap: 0.625rem;
}

.testimonial-container .testimonial-card .testimonial-content .testimonial-company img {
    width: auto;
    height: 72px;
}

.testimonial-container .testimonial-card .testimonial-content .testimonial-heading {
    font-family: Inter;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2.48625rem;
    display: inline-block;
}

.testimonial-container .testimonial-card .testimonial-content .testimonial-quote {
    font-family: Inter;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 2.54625rem;
    /* 40.74px */
}

.testimonial-container .testimonial-card .testimonial-content .testimonial-navigation {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    /* 10px */
}

.testimonial-container .testimonial-card .testimonial-content .testimonial-navigation .testimonial-navigation-button {
    width: 5.30375rem;
    /* 84.86px */
    height: 5.30375rem;
    /* 84.86px */
    display: grid;
    place-items: center;
    border-radius: 0.994375rem;
    /* 15.91px */
    border: 0.165625rem solid rgba(255, 159, 96, 1);
    /* 2.65px */
    box-shadow: 0px 0.33125rem 0.663125rem -0.165625rem rgba(16, 24, 40, 0.1);
    /* 5.3px 10.61px -2.65px */
    background: rgba(255, 255, 255, 1);
    color: rgba(255, 159, 96, 1);
    font-size: 2rem;
    font-weight: 700;
}

/* Media Queries */
@media (min-width: 100px) and (max-width: 500px) {
    .testimonial-container {
        padding: 10px;
    }

    .testimonial-container .testimonial-card {
        width: 100%;
    }
}

@media (min-width: 500px) and (max-width: 900px) {
    .testimonial-container {
        padding: 10px;
    }

    .testimonial-container .testimonial-card {
        width: 100%;
    }
}

@media (min-width: 100px) and (max-width: 400px) {
    .testimonial-container .testimonial-card .testimonial-image {
        width: auto;
        height: 102px;
    }

    .testimonial-container .testimonial-card .testimonial-content .testimonial-company img {
        width: auto;
        height: 13px;
    }
}

@media (min-width: 400px) and (max-width: 500px) {
    .testimonial-container .testimonial-card .testimonial-image {
        width: auto;
        height: 128px;
    }

    .testimonial-container .testimonial-card .testimonial-content .testimonial-company img {
        width: auto;
        height: 14px;
    }
}

@media (min-width: 500px) and (max-width: 700px) {
    .testimonial-container .testimonial-card .testimonial-image {
        width: auto;
        height: 153px;
    }

    .testimonial-container .testimonial-card .testimonial-content .testimonial-company img {
        width: auto;
        height: 18px;
    }
}

@media (min-width: 700px) and (max-width: 800px) {
    .testimonial-container .testimonial-card .testimonial-image {
        width: auto;
        height: 204px;
    }

    .testimonial-container .testimonial-card .testimonial-content .testimonial-company img {
        width: auto;
        height: 18px;
    }
}

@media (min-width: 800px) and (max-width: 900px) {
    .testimonial-container .testimonial-card .testimonial-image {
        width: auto;
        height: 230px;
    }

    .testimonial-container .testimonial-card .testimonial-content .testimonial-company img {
        width: auto;
        height: 18px;
    }
}

@media (min-width: 900px) and (max-width: 1000px) {
    .testimonial-container .testimonial-card .testimonial-image {
        width: auto;
        height: 256px;
    }

    .testimonial-container .testimonial-card .testimonial-content .testimonial-company img {
        width: auto;
        height: 23px;
    }
}

@media (min-width: 1000px) and (max-width: 1100px) {
    .testimonial-container .testimonial-card .testimonial-image {
        width: auto;
        height: 281px;
    }

    .testimonial-container .testimonial-card .testimonial-content .testimonial-company img {
        width: auto;
        height: 23px;
    }
}

@media (min-width: 1100px) and (max-width: 1200px) {
    .testimonial-container .testimonial-card .testimonial-image {
        width: auto;
        height: 306px;
    }

    .testimonial-container .testimonial-card .testimonial-content .testimonial-company img {
        width: auto;
        height: 23px;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .testimonial-container .testimonial-card .testimonial-image {
        width: auto;
        height: 332px;
    }

    .testimonial-container .testimonial-card .testimonial-content .testimonial-company img {
        width: auto;
        height: 28px;
    }
}

@media (min-width: 1400px) and (max-width: 1500px) {
    .testimonial-container .testimonial-card .testimonial-image {
        width: auto;
        height: 357px;
    }

    .testimonial-container .testimonial-card .testimonial-content .testimonial-company img {
        width: auto;
        height: 34px;
    }
}

@media (min-width: 1500px) and (max-width: 1600px) {
    .testimonial-container .testimonial-card .testimonial-image {
        width: auto;
        height: 383px;
    }

    .testimonial-container .testimonial-card .testimonial-content .testimonial-company img {
        width: auto;
        height: 44px;
    }
}

@media (min-width: 1600px) and (max-width: 1700px) {
    .testimonial-container .testimonial-card .testimonial-image {
        width: auto;
        height: 408px;
    }

    .testimonial-container .testimonial-card .testimonial-content .testimonial-company img {
        width: auto;
        height: 52px;
    }
}

@media (min-width: 1700px) and (max-width: 1800px) {
    .testimonial-container .testimonial-card .testimonial-image {
        width: auto;
        height: 435px;
    }

    .testimonial-container .testimonial-card .testimonial-content .testimonial-company img {
        width: auto;
        height: 60px;
    }
}

@media (min-width: 1800px) and (max-width: 2000px) {
    .testimonial-container .testimonial-card .testimonial-image {
        width: auto;
        height: 459px;
    }

    .testimonial-container .testimonial-card .testimonial-content .testimonial-company img {
        width: auto;
        height: 66px;
    }
}
