@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.aboutUsContainer {
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.aboutUsContainer::-webkit-scrollbar {
    display: none;
}

.containerContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.containerContent .container-content-header {
    width: 100%;
    height: 25rem;
    display: grid;
    place-items: center;
    gap: 1rem;
    position: relative;
}

.containerContent .container-content-header .container-content-header-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}

.containerContent .container-content-header .container-content-header-content .container-content-header-h1{
    font-size: 5rem;
    text-wrap: nowrap;
}

.containerContent .container-content-header .container-content-header-content .container-content-header-h2 {
    font-size: 5rem;
    text-wrap: nowrap;
    position: relative;
}

.containerContent .container-content-header .container-content-header-content .container-content-header-h2::after {
    content: 'Our Mission';
    width: max-content;
    position: absolute;
    top: 4rem;
    left: 39rem;
    font-size: 1.4rem;
    font-weight: 500;
    text-wrap: nowrap;
}

.aboutUs-section-3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.aboutUs-section-3 .whoWeAre-row {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.aboutUs-section-3 .whoWeAre-row .title {
    font-size: 4.5rem;
    font-family: Inter;
    font-weight: 700;
}

.aboutUs-section-3 .whoWeAre-row .description {
    font-size: 2.2rem;
    line-height: 5rem;
}

.aboutUs-section-3 .ourKeyValues-row {
    width: 80%;
    height: 50rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.aboutUs-section-3 .ourKeyValues-row .leftSideContent {
    width: 42%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}

.aboutUs-section-3 .ourKeyValues-row .leftSideContent .title {
    font-family: Inter;
    font-size: 4rem;
    font-weight: 700;
    line-height: 5rem;
}

.aboutUs-section-3 .ourKeyValues-row .leftSideContent .description {
    font-family: Poppins;
    font-size: 2.2rem;
    font-weight: 400;
    line-height: 4rem;
}

.aboutUs-section-3 .ourKeyValues-row .rightSideContent {
    width: 50%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 20rem);
    gap: 2rem;
    padding: 1rem;
    place-items: center;
}

.aboutUs-section-3 .ourKeyValues-row .rightSideContent .card {
    width: 18rem;
    height: 19rem;
    gap: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border-top: 2.5px solid rgba(255, 159, 96, 1);
    box-shadow: 0px 13.19px 65.95px 0px rgba(34, 47, 62, 0.1);
    background-color: white;
    padding-top: 2.8rem;
}

.aboutUs-section-3 .ourKeyValues-row .rightSideContent .card img {
    width: auto;
    height: 48px;
}

.aboutUs-section-3 .ourKeyValues-row .rightSideContent .card .title {
    font-family: Inter;
    font-size: 2rem;
    font-weight: 600;
    margin-top: 1rem;
}

.aboutUs-section-3 .ourKeyValues-row .rightSideContent .card .description {
    font-size: 1.2rem;
    color: #121212;
    opacity: 0.7;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    margin-top: 1rem;
}

.aboutUs-section-3 .ourTeam-row {
    width: 80%;
    height: 30rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.aboutUs-section-3 .ourTeam-row .ourTeam-row-header {
    font-family: Inter;
    font-size: 4rem;
    font-weight: 700;
}

.aboutUs-section-3 .ourTeam-row .teamCard {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
}

.aboutUs-section-3 .ourTeam-row .teamCard .card {
    width: 28rem;
    height: 38rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.aboutUs-section-3 .ourTeam-row .teamCard .card .description {
    font-size: 2rem;
    text-align: justify;
    letter-spacing: 1px;
}

.aboutUs-section-3 .ourTeam-row .teamCard .card .image {
    width: auto;
    height: 70%;
    background: rgba(255, 237, 226, 1);
    border-radius: 2.8rem;
}

.aboutUs-section-3 .ourTeam-row .teamCard .card .image img {
    width: auto;
    height: 393.6px;
}

.our-presence {
    width: 80%;
    height: 120rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 35rem;
    padding: 2rem;
}

.our-presence .our-presence-header-content {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.our-presence .our-presence-header-content .our-presence-header {
    font-family: Inter;
    font-size: 6rem;
    font-weight: 700;
}

.our-presence .our-presence-header-content .our-presence-description {
    font-family: Open Sans;
    font-size: 2rem;
    font-weight: 400;
    line-height: 4.2rem;
    
}

.our-presence .our-presence-info {
    width: 100%;
    height: 68rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 5rem;
}

.our-presence .our-presence-info .our-presence-image {
    width: 60%;
    height: 100%;
    display: grid;
    place-items: center;
}

.our-presence .our-presence-info .our-presence-image img {
    width: auto;
    height: 1040px;
}

.our-presence .our-presence-info .our-presence-stats {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.our-presence .our-presence-info .our-presence-stats .our-presence-stats-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.our-presence .our-presence-info .our-presence-stats .our-presence-stats-item .our-presence-stats-item-number {
    font-family: Inter;
    font-size: 7rem;
    font-weight: 700;
}

.our-presence .our-presence-info .our-presence-stats .our-presence-stats-item .our-presence-stats-item-label {
    font-family: Inter;
    font-size: 5.5rem;
    font-weight: 300;
}



@media (min-width: 100px) and (max-width: 600px) {
    .aboutUsContainer .containerContent .aboutUs-section-3 {
        padding: 1rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .whoWeAre-row {
        width: 100%;
        align-items: center;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .whoWeAre-row .description {
        text-align: center;
        text-wrap: balance;
        font-size: 2.1rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourKeyValues-row {
        width: 100%;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        height: 70rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourKeyValues-row .leftSideContent {
        width: 100%;
        align-items: center;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourKeyValues-row .leftSideContent .description {
        text-align: center;
        text-wrap: balance;
        font-size: 2rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourKeyValues-row .rightSideContent {
        width: 100%;
        gap: 1rem;
        place-content: center;
        row-gap: 2rem;
        grid-template-columns: repeat(2, 20rem);
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourKeyValues-row .rightSideContent .card {
        width: 18rem;
        height: 21rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row {
        width: 100%;
        height: 346rem;
        align-items: center;
    }

    .our-presence .our-presence-header-content .our-presence-description {
        font-family: Open Sans;
        font-size: 3rem;
        font-weight: 400;
        line-height: 4.2rem;
        text-align: center;
        text-wrap: balance;
    }
    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .ourTeam-row-header {
        font-size: 7rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard {
        height: 100%;
        flex-direction: column;
        gap: 6rem;
    }

    .aboutUs-section-3 .ourTeam-row .teamCard .card .description {
        font-size: 3rem;
        text-align: justify;
        letter-spacing: 1px;
    }

    .aboutUs-section-3 .ourTeam-row .teamCard .card .image {
        width: auto;
        height: 70%;
        background: rgba(255, 237, 226, 1);
        border-radius: 10.8rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card {
        width: 69rem;
        height: 88rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card .image {
        height: 100%;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card .image img {
        width: auto;
        height: 233px;
    }

    .aboutUsContainer .containerContent .our-presence {
        width: 100%;
        height: 179rem;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-header-content {
        align-items: center;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info {
        width: 100%;
        height: 127rem;
        flex-direction: column;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-stats {
        width: 100%;
        flex-direction: row;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-stats .our-presence-stats-item {
        width: 26rem;
        height: 6rem;
        text-wrap: wrap;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-stats .our-presence-stats-item .our-presence-stats-item-label {
        text-wrap: wrap;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-image {
        width: 100%;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourKeyValues-row .rightSideContent .card img {
        width: auto;
        height: 17px;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-image img {
        height: 265px;
    }
}

@media (min-width: 600px) and (max-width: 800px) {
    .aboutUsContainer .containerContent .aboutUs-section-3 .ourKeyValues-row .rightSideContent .card img {
        width: auto;
        height: 20px;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info {
        height: 53rem;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-stats .our-presence-stats-item .our-presence-stats-item-number {
        font-size: 4.5rem;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-stats .our-presence-stats-item .our-presence-stats-item-label {
        font-size: 3rem;
    }
}

@media (min-width: 800px) and (max-width: 1000px) {
    .aboutUsContainer .containerContent .our-presence .our-presence-info {
        height: 65rem;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-stats .our-presence-stats-item .our-presence-stats-item-number {
        font-size: 6rem;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-stats .our-presence-stats-item .our-presence-stats-item-label {
        font-size: 4.5rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourKeyValues-row .rightSideContent .card img {
        width: auto;
        height: 22px;
    }
}

@media (min-width: 1000px) and (max-width: 1400px) {
    .aboutUsContainer .containerContent .our-presence .our-presence-info {
        height: 55rem;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-stats .our-presence-stats-item .our-presence-stats-item-number {
        font-size: 5rem;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-stats .our-presence-stats-item .our-presence-stats-item-label {
        font-size: 3.5rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourKeyValues-row .rightSideContent .card img {
        width: auto;
        height: 32px;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card .image img {
        width: auto;
        height: 353.6px;
    }
}

@media (min-width: 1400px) and (max-width: 2000px) {
    .aboutUsContainer .containerContent .our-presence .our-presence-info {
        height: 60rem;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-stats .our-presence-stats-item .our-presence-stats-item-number {
        font-size: 5rem !important;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-stats .our-presence-stats-item .our-presence-stats-item-label {
        font-size: 4rem !important;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard {
        gap: 1.5rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card {
        width: 25rem !important;
        height: 35rem !important;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card .description {
        font-size: 1.5rem !important;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourKeyValues-row .rightSideContent .card img {
        width: auto;
        height: 45px;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-image img {
        height: 800px;
    }
}

@media (min-width: 100px) and (max-width: 400px) {
    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard {
        gap: 4rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card .image img {
        width: auto;
        height: 233px;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-image img {
        height: 265px;
    }
}

@media (min-width: 400px) and (max-width: 500px) {
    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard {
        gap: 4rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card .image img {
        width: auto;
        height: 288.6px;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-image img {
        height: 336px;
    }
}

@media (min-width: 500px) and (max-width: 600px) {
    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard {
        gap: 4rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card .image img {
        width: auto;
        height: 357.6px;
    }

    .aboutUs-section-3 .ourTeam-row .teamCard .card .image {
        border-radius: 10.8rem;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-image img {
        height: 390px;
    }
}

@media (min-width: 600px) and (max-width: 700px) {
    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard {
        gap: 4rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card .image img {
        width: auto;
        height: 118.6px;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-image img {
        height: 255px;
    }
}

@media (min-width: 700px) and (max-width: 800px) {
    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard {
        gap: 4rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card .image img {
        width: auto;
        height: 128.6px;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-image img {
        height: 339px;
    }
}

@media (min-width: 800px) and (max-width: 900px) {
    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard {
        gap: 4rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card .image img {
        width: auto;
        height: 143.6px;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-image img {
        height: 430px;
    }
}

@media (min-width: 900px) and (max-width: 1000px) {
    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard {
        gap: 4rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card .image img {
        width: auto;
        height: 167.6px;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-image img {
        height: 488px;
    }
}

@media (min-width: 1000px) and (max-width: 1100px) {
    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard {
        gap: 4rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card .image img {
        width: auto;
        height: 188.6px;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-image img {
        height: 488px;
    }
}

@media (min-width: 1100px) and (max-width: 1200px) {
    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard {
        gap: 4rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card .image img {
        width: auto;
        height: 204.6px;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-image img {
        height: 584px;
    }
}

@media (min-width: 1200px) and (max-width: 1300px) {
    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard {
        gap: 4rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card .image img {
        width: auto;
        height: 223.6px;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-image img {
        height: 584px;
    }
}

@media (min-width: 1300px) and (max-width: 1400px) {
    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard {
        gap: 4rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card .image img {
        width: auto;
        height: 246.6px;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-image img {
        height: 584px;
    }
}

@media (min-width: 1400px) and (max-width: 1500px) {
    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard {
        gap: 4rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card .image img {
        width: auto;
        height: 275.6px;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-image img {
        height: 737px;
    }
}

@media (min-width: 1500px) and (max-width: 1600px) {
    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard {
        gap: 4rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card .image img {
        width: auto;
        height: 295.6px;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-image img {
        height: 800px;
    }
}

@media (min-width: 1600px) and (max-width: 1800px) {
    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard {
        gap: 4rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card .image img {
        width: auto;
        height: 312.6px;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-image img {
        height: 800px;
    }
}

@media (min-width: 1800px) and (max-width: 2000px) {
    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard {
        gap: 3rem;
    }

    .aboutUsContainer .containerContent .aboutUs-section-3 .ourTeam-row .teamCard .card .image img {
        width: auto;
        height: 353.6px;
    }

    .aboutUsContainer .containerContent .our-presence .our-presence-info .our-presence-image img {
        height: 800px;
    }
}




