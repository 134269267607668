.howToApplyContainer {
    width: 100%;
    height: 100.5rem;
    display: grid;
    place-items: center;
    background-color: #FFFFFF;
}

.howToApplyContent {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerContent {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.875rem; /* 30px -> 1.875rem */
    height: 20%;
    margin-top: 15.25rem; 

}

.headerContent h1 {
    font-family: Inter;
    font-size: 5.8rem; /* 80px -> 4.8rem */
    font-weight: 700;
}

.headerContent p {
    width: 90%;
    font-family: Open Sans;
    font-size: 1.26rem; /* 15.2px -> 0.96rem */
    font-weight: 400;
    line-height: 2.25rem; /* 36px -> 2.25rem */
    text-align: center;
    text-wrap: balance;
    color: rgba(0, 0, 0, 1);
}

.bodyContent {
    width: 100%;
    height: 64%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.stepsContent {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 3.5rem; /* 40px -> 2.5rem */
}

.step {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1.25rem; /* 20px -> 1.25rem */
}

.stepNumber {
    position: relative;
    width: 11.485625rem; /* 103.77px -> 6.485625rem */
    height: 11.861875rem; /* 109.79px -> 6.861875rem */
    flex-shrink: 0;
    border-radius: 1.835rem; /* 29.36px -> 1.835rem */
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(0, 0, 0, 1); /* 0.75px -> 0.046875rem */
    box-shadow: 0rem 0.188125rem 0.188125rem 0rem rgba(0, 0, 0, 0.25); /* 0px 3.01px 3.01px 0px -> 0rem 0.188125rem 0.188125rem 0rem */
    font-family: Inter;
    font-size: 3.25rem; /* 36.13px -> 2.25rem */
    font-weight: 300;
    display: grid;
    place-items: center;
}

.stepNumber img {
    position: absolute;
    top: 11.9rem;
    left: 5rem;
    height: 3.375rem;
}

.stepDetails {
    display: flex;
    flex-direction: column;
    gap: 1.25rem; /* 20px -> 1.25rem */
}

.stepHeading {
    font-family: Inter;
    font-size: 4.075rem; /* 30px -> 1.875rem */
    font-weight: 600;
}

.stepDescription {
    font-family: Inter;
    font-size: 2.25rem; /* 20px -> 1.25rem */
    font-weight: 500;
}

.imageSlider {
    width: 50%;
    height: 100%;
    display: flex;
    place-items: center;
    position: relative;
    transition: 0.5s all ease-in-out;
}

.imageSlider .phoneImage {
    position: absolute;
    inset: 0;
    margin: auto;
    transition: 0.5s all ease-in-out;
    width: 30rem;
    height: 100%;

    
}

.imageSlider .phoneImage img {
    width: auto;
    height: 1056px;
}

.imageSlider .phoneImage:nth-child(3){
    height: 95%;
    top: -1.5rem;

}

.imageSlider .phoneImage:nth-child(2){
    
    top: -1.5rem;

}


@media (min-width: 100px) and (max-width: 600px) {
    .howToApplyContainer{
    height: 171.5rem;

    }

    .howToApplyContent {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 12.25rem;
    }

    .headerContent{
        width: 100%;
        height: 15%;
    }

    .headerContent h1 {
        font-size: 7.8rem;
    }

    .headerContent p{
        font-size: 2.96rem;
        font-weight: 400;
        line-height: 5.25rem;
    
    }

    .stepHeading{
        font-size: 3rem;
    }

    .stepNumber img{
        opacity: 0;
    }

    .bodyContent{
        flex-direction: column-reverse;
        height: 77%;
        padding: 2rem;

        
    }

    .bodyContent .stepsContent{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 13rem;
    }

    .step{
        gap: 2rem;
    }

}

@media (min-width: 600px) and (max-width: 1100px) {
    .howToApplyContainer{
    height: 113.5rem;

    }

    .howToApplyContent {
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 8.25rem;
    }

    .stepHeading{
        font-size: 2rem;
    }

    .headerContent{
        width: 100%;
    }

    .headerContent h1 {
        font-size: 7.8rem;
    }

    .headerContent p{
        font-size: 2.96rem;
        font-weight: 400;
        line-height: 5.25rem;
    
    }

    .bodyContent{
        padding: 2rem;
        
    }

    .bodyContent .imageSlider{
        height: 100%;
    }
}



@media (min-width: 1100px) and (max-width: 1400px) {
    .stepHeading{
        font-size: 3rem;
    }

    .stepDescription{
        font-size: 2rem;
    }
}

@media (min-width: 1400px) and (max-width: 2000px) {

    .howToApplyContainer{
        height: 85rem;
    }


    .stepHeading{
        font-size: 2.5rem;
    }

    .stepDescription{
        font-size: 2rem;
    }

    .stepNumber{
        width: 9rem;
        height: 9rem;
    }

  


    

    .stepNumber img{
        top: 9rem;
        left: 4rem;
    }

    .headerContent{
        margin-top: 20rem;
    }


}


/* Media Queries for phoneImage */
@media (min-width: 100px) and (max-width: 400px) {
    

    .imageSlider .phoneImage img{
        height: 236px;
    }
}

@media (min-width: 400px) and (max-width: 500px) {
    

    .imageSlider .phoneImage img{
        height: 281px;
    }
}

@media (min-width: 500px) and (max-width: 600px) {
    

    .imageSlider .phoneImage img{
        height: 338px;
    }
}

@media (min-width: 600px) and (max-width: 700px) {
    

    .imageSlider .phoneImage img{
        height: 338px;
    }

    .stepNumber img{
        height: 3.175rem;
    }

    
}

@media (min-width: 700px) and (max-width: 800px) {
    

    .imageSlider .phoneImage img{
        height: 438px;
    }
}


@media (min-width: 800px) and (max-width: 1000px) {
    

    .imageSlider .phoneImage img{
        height: 540px;
    }
}

@media (min-width: 1000px) and (max-width: 1200px) {
    

    .imageSlider .phoneImage img{
        height: 633px;
    }
}


@media (min-width: 1200px) and (max-width: 1600px) {
    

    .imageSlider .phoneImage img{
        height: 653px;
    }
}


@media (min-width: 1600px) and (max-width: 1800px) {
    

    .imageSlider .phoneImage img{
        height: 708px;
    }
}


@media (min-width: 1800px) and (max-width: 2000px) {
    

    .imageSlider .phoneImage img{
        height: 770px;
    }
}