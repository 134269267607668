.financing-content {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: 2.5rem;
    margin-top: 5%;
}

.financing-content .heading-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.financing-content .heading-section .main-title {
    font-family: Inter;
    font-size: 5.25rem;
    font-weight: 700;
}

.financing-content .heading-section .main-description {
    font-family: Open Sans;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2.25rem;
}

.financing-content .businessLoanContent,
.financing-content .securedLoanContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.financing-content .businessLoanContent div,
.financing-content .securedLoanContent div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.financing-content .businessLoanContent .title,
.financing-content .securedLoanContent .title {
    font-family: Inter;
    font-size: 3rem;
}

.financing-content .businessLoanContent .description,
.financing-content .securedLoanContent .description {
    font-family: Open Sans;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2.25rem;
}

.financing-content .card-grid {
    display: grid;
    grid-template-columns: repeat(2, 29rem);
    place-content: center;
    gap: 7.75rem;
}

.financing-content .card-grid .card {
    width: 30rem;
    height: 28rem;
    position: relative;
    border: 0.09125rem solid rgba(153, 223, 235, 1);
    border-radius: 0.625rem;
    padding: 1.25rem;
    overflow: hidden;
    background: rgba(255, 158, 94, 0.18);
}

.financing-content .card-grid .card-1 .semi-circle {
    top: 0;
    left: 0;
    rotate: 180deg;
}

.financing-content .card-grid .card-2 .semi-circle {
    top: 0;
    right: 0;
    rotate: 270deg;
}

.financing-content .card-grid  .card-3 .semi-circle {
    bottom: 0;
    left: 0;
    rotate: 90deg;
}

.financing-content .card-grid  .card-4 .semi-circle {
    bottom: 0;
    right: 0;
    rotate: 360deg;
}

.financing-content .card-grid .card h2 {
    width: 100%;
    text-transform: capitalize;
}

.financing-content .card-grid .card a {
    text-decoration: none;
}

.financing-content .card-grid .card .description {
    font-family: Inter;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.79125rem;
    color: rgba(148, 151, 161, 1);
}

.financing-content .card-grid .card .readMore {
    font-family: Inter;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.79125rem;
    color: rgba(230, 102, 82, 1);
}

.financing-content .card-grid .card .mainImage {
    width: auto;
    height: 224px;
    z-index: 2;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 0;
}

.financing-content .card-grid .card .titleImage {
    width: auto;
    height: 6rem;
    position: absolute;
    top: 7rem;
    left: 17rem;
    z-index: 1;
}

.financing-content .card-grid .card .semi-circle {
    position: absolute;
    width: 12.543125rem;
    height: 12.543125rem;
    border-radius: 15.043125rem 0 0 0;
    background: rgba(255, 158, 94, 0.18);
}

.financing-content .card-grid .card .overlay {
    position: absolute;
    background-color: rgba(255, 158, 94, 0.18);
    border-radius: 50%;
    width: 17rem;
    height: 17rem;
    top: 12rem;
    left: 8rem;
}

.WhatMakesUsDifferent .card-grid {
    grid-template-columns: repeat(3, 1fr);
}

.WhatMakesUsDifferent .card-grid .card .mainImage {
    height: 288px;
    top: 68%;
}

@media (min-width: 100px) and (max-width: 400px) {
    .section-2-Container .financing-content .heading-section {
        width: 100%;
        padding: 10px;
    }

    .section-2-Container .financing-content .card-grid {
        grid-template-columns: repeat(1, 60rem);
    }

    .section-2-Container .financing-content .card-grid .card {
        width: 60rem;
        height: 60rem;
        border-radius: 2rem;
        padding: 5rem;
    }

    .section-2-Container .financing-content .card-grid .card h2 {
        font-size: 6rem;
    }

    .section-2-Container .financing-content .card-grid .card .description {
        font-size: 2.5rem;
        margin-top: 2rem;
        line-height: 3rem;
    }

    .section-2-Container .financing-content .heading-section .main-title {
        font-size: 10.25rem;
    }

    .section-2-Container .financing-content .heading-section .main-description {
        font-size: 2.2rem;
        font-weight: 400;
        line-height: 5.25rem;
    }

    .section-2-Container .financing-content .card-grid .card .readMore {
        font-size: 2.5rem;
        margin-top: 2rem;
        line-height: 3rem;
    }

    .section-2-Container .financing-content .card-grid .card .mainImage {
        height: 83px;
        top: 78%;
    }

    .section-2-Container .WhatMakesUsDifferent {
        height: 245rem;
    }

    .section-2-Container .WhatMakesUsDifferent .card-grid {
        grid-template-columns: repeat(1, 60rem);
        place-content: center;
    }

    .section-2-Container .WhatMakesUsDifferent .card-grid .card {
        width: 60rem;
        height: 60rem;
    }

    .section-2-Container .WhatMakesUsDifferent .card-grid .card h2 {
        font-size: 4rem;
    }

    .section-2-Container .WhatMakesUsDifferent .card-grid .card .mainImage {
        height: 122px;
        top: 68%;
    }

    .section-2-Container .WhatMakesUsDifferent .card-grid .card .overlay {
        width: 40rem;
        height: 40rem;
        top: 28rem;
        left: 13rem;
    }

    .section-2-Container .WhatMakesUsDifferent .card-grid .card .titleImage {
        height: 13rem;
        top: 16rem;
        left: 35rem;
    }
}

@media (min-width: 400px) and (max-width: 500px) {
    .section-2-Container .financing-content .card-grid {
        grid-template-columns: repeat(1, 60rem);
        place-content: center;
    }

    .section-2-Container .financing-content .heading-section .main-title {
        font-size: 10.25rem;
    }

    .section-2-Container .financing-content .heading-section .main-description {
        font-size: 2.2rem;
        font-weight: 400;
        line-height: 5.25rem;
    }

    .section-2-Container .financing-content .card-grid .card {
        width: 60rem;
        height: 60rem;
        border-radius: 2rem;
        padding: 5rem;
    }

    .section-2-Container .financing-content .card-grid .card h2 {
        font-size: 6rem;
    }

    .section-2-Container .financing-content .card-grid .card .description {
        font-size: 2.5rem;
        margin-top: 2rem;
        line-height: 3rem;
    }

    .section-2-Container .financing-content .card-grid .card .readMore {
        font-size: 2.5rem;
        margin-top: 2rem;
        line-height: 3rem;
        cursor: pointer;
    }

    .section-2-Container .financing-content .card-grid .card .mainImage {
        height: 125px;
        top: 78%;
    }

    .section-2-Container .WhatMakesUsDifferent {
        height: 245rem;
    }

    .section-2-Container .WhatMakesUsDifferent .card-grid {
        grid-template-columns: repeat(1, 60rem);
        place-content: center;
    }

    .section-2-Container .WhatMakesUsDifferent .card-grid .card {
        width: 60rem;
        height: 60rem;
    }

    .section-2-Container .WhatMakesUsDifferent .card-grid .card h2 {
        font-size: 4rem;
    }

    .section-2-Container .WhatMakesUsDifferent .card-grid .card .mainImage {
        height: 153px;
        top: 68%;
    }

    .section-2-Container .WhatMakesUsDifferent .card-grid .card .overlay {
        width: 40rem;
        height: 40rem;
        top: 28rem;
        left: 13rem;
    }

    .section-2-Container .WhatMakesUsDifferent .card-grid .card .titleImage {
        height: 13rem;
        top: 16rem;
        left: 35rem;
    }
}

@media (min-width: 500px) and (max-width: 600px) {
    .section-2-Container .financing-content .card-grid {
        grid-template-columns: repeat(1, 60rem);
        place-content: center;
    }

    .section-2-Container .financing-content .heading-section .main-title {
        font-size: 10.25rem;
    }

    .section-2-Container .financing-content .heading-section .main-description {
        font-size: 2.2rem;
        font-weight: 400;
        line-height: 5.25rem;
    }

    .section-2-Container .financing-content .card-grid .card {
        width: 60rem;
        height: 60rem;
        border-radius: 2rem;
        padding: 5rem;
    }

    .section-2-Container .financing-content .card-grid .card h2 {
        font-size: 6rem;
    }

    .section-2-Container .financing-content .card-grid .card .description {
        font-size: 2.5rem;
        margin-top: 2rem;
        line-height: 3rem;
    }

    .section-2-Container .financing-content .card-grid .card .readMore {
        font-size: 2.5rem;
        margin-top: 2rem;
        line-height: 3rem;
        cursor: pointer;
    }

    .section-2-Container .financing-content .card-grid .card .mainImage {
        height: 125px;
        top: 78%;
    }

    .section-2-Container .WhatMakesUsDifferent {
        height: 245rem;
    }

    .section-2-Container .WhatMakesUsDifferent .card-grid {
        grid-template-columns: repeat(1, 60rem);
        place-content: center;
    }

    .section-2-Container .WhatMakesUsDifferent .card-grid .card {
        width: 60rem;
        height: 60rem;
    }

    .section-2-Container .WhatMakesUsDifferent .card-grid .card h2 {
        font-size: 4rem;
    }

    .section-2-Container .WhatMakesUsDifferent .card-grid .card .mainImage {
        height: 183px;
        top: 68%;
    }

    .section-2-Container .WhatMakesUsDifferent .card-grid .card .overlay {
        width: 40rem;
        height: 40rem;
        top: 28rem;
        left: 13rem;
    }

    .section-2-Container .WhatMakesUsDifferent .card-grid .card .titleImage {
        height: 13rem;
        top: 16rem;
        left: 35rem;
    }
}

@media (min-width: 600px) and (max-width: 700px) {
    .section-2-Container .WhatMakesUsDifferent .card-grid .card .mainImage {
        height: 86px;
    }

    .financing-content .card-grid .card .mainImage {
        height: 66px;
    }
}

@media (min-width: 700px) and (max-width: 800px) {
    .section-2-Container .WhatMakesUsDifferent .card-grid .card .mainImage {
        height: 114px;
    }

    .financing-content .card-grid .card .mainImage {
        height: 89px;
    }
}

@media (min-width: 800px) and (max-width: 1000px) {
    .section-2-Container .WhatMakesUsDifferent .card-grid .card .mainImage {
        height: 141px;
    }

    .financing-content .card-grid .card .mainImage {
        height: 110px;
    }
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .section-2-Container .WhatMakesUsDifferent .card-grid .card .mainImage {
        height: 165px;
    }

    .financing-content .card-grid .card .mainImage {
        height: 133px;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .section-2-Container .WhatMakesUsDifferent .card-grid .card .mainImage {
        height: 183px;
    }

    .financing-content .card-grid .card .mainImage {
        height: 144px;
    }
}

@media (min-width: 1400px) and (max-width: 1700px) {
    .section-2-Container .WhatMakesUsDifferent .card-grid .card .mainImage {
        height: 225px;
    }

    .financing-content .card-grid .card .mainImage {
        height: 176px;
    }
}

@media (min-width: 1700px) and (max-width: 2000px) {
    .section-2-Container .WhatMakesUsDifferent .card-grid .card .mainImage {
        height: 256px;
    }

    .financing-content .card-grid .card .mainImage {
        height: 198px;
    }
}
