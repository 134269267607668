.section-2-Container {
    width: 100%;
    display: grid;
    place-items: center;
    background-color: #FFFFFF;
}

.section-2-Container .container {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.section-2-Container .container .row1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.section-2-Container .container .row1 .leftSide {
    width: 33.25rem;
    height: 33.25rem;
    position: relative;
}

.section-2-Container .container .row1 .leftSide img {
    width: auto;
    height: 452.2px;
}

.section-2-Container .container .row1 .rightSide {
    width: 33.25rem;
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
}

.section-2-Container .container .row1 .rightSide .rightSide-header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: 3.4rem;
}

.section-2-Container .container .row1 .rightSide .rightSide-header .rightSide-header-h1 {
    font-family: Inter;
    font-size: 2.418125rem; /* 46.69px */
    font-weight: 700;
    line-height: 3.2rem;
    text-wrap: wrap;
}

.section-2-Container .container .row1 .rightSide .rightSide-content {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
}

.section-2-Container .container .row1 .rightSide .rightSide-content .rightSide-content-item {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 1.4rem;
}

.section-2-Container .container .row1 .rightSide .rightSide-content .rightSide-content-item img {
    width: auto;
    height: 32px;
}

.section-2-Container .container .row2 {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.section-2-Container .container .row2 .leftSide {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
}

.section-2-Container .container .row2 .leftSide .leftSide-header-h1 {
    font-family: Inter;
    font-size: 2.918125rem; /* 46.69px */
    font-weight: 700;
    line-height: 3.9rem;
    text-wrap: wrap;
}

.section-2-Container .container .row2 .leftSide .leftSide-content {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
}

.section-2-Container .container .row2 .leftSide .leftSide-content .leftSide-content-item {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 1.4rem;
}

.section-2-Container .container .row2 .leftSide .leftSide-content .leftSide-content-item img {
    width: auto;
    height: 32px;
}

.section-2-Container .container .row2 .leftSide .leftSide-content .leftSide-content-item .knowMore {
    color: rgba(230, 102, 82, 1);
}

.section-2-Container .container .row2 .rightSide {
    width: 30.25rem;
    height: 30.25rem;
    position: relative;
}

.section-2-Container .container .row2 .rightSide img {
    width: auto;
    height: 411.4px;
}

.section-2-Container .WhatMakesUsDifferent .card-grid {
    place-content: unset;
}

.section-2-Container .WhatMakesUsDifferent .card-grid .card-2 .card-image {
    bottom: -13rem;
}

/* Media Queries */
@media (min-width: 100px) and (max-width: 400px) {
    .section-2-Container .container {
        width: 80%;
        margin-top: 5%;
        gap: 50px;
    }

    .section-2-Container .container .row1 {
        padding: 1rem;
        justify-content: space-between;
        align-items: flex-start;
        gap: 1px;
    }

    .section-2-Container .container .row1 .rightSide {
        width: 42%;
        gap: 10px;
        display: grid;
        place-items: center;
    }

    .section-2-Container .container .row1 .rightSide .rightSide-header .rightSide-header-h1 {
        font-size: 2rem;
    }

    .section-2-Container .container .row1 .rightSide .rightSide-content .rightSide-content-item {
        gap: 5px;
    }

    .section-2-Container .container .row1 .leftSide {
        width: 20.25rem;
        height: 18.25rem;
    }

    .section-2-Container .container .row1 .leftSide img {
        height: 89.2px;
    }

    .section-2-Container .container .row2 {
        justify-content: space-around;
        gap: 1px;
    }

    .section-2-Container .container .row2 .leftSide {
        width: 40%;
        gap: 5px;
        margin-left: 2%;
        align-self: flex-start;
    }

    .section-2-Container .container .row2 .leftSide .leftSide-content li {
        gap: 5px;
    }

    .section-2-Container .container .row2 .leftSide .leftSide-header-h1 {
        font-size: 1.9rem;
    }

    .section-2-Container .container .row2 .leftSide .leftSide-header-p {
        font-size: 1.5rem;
    }

    .section-2-Container .container .row1 .rightSide .rightSide-content .rightSide-content-item img {
        width: auto;
        height: 6px;
    }

    .section-2-Container .container .row2 .leftSide .leftSide-content .leftSide-content-item img {
        width: auto;
        height: 6px;
    }

    .section-2-Container .container .row2 .rightSide img {
        height: 100.4px;
    }
    .section-2-Container .container .row1 .rightSide .rightSide-header {
        
        margin-left: 2.9rem;
    }
}

@media (min-width: 400px) and (max-width: 500px) {
    .section-2-Container .container .row1 .leftSide img {
        height: 112.2px;
    }

    .section-2-Container .container .row1 .rightSide {
        width: 28.25rem;
    }

    .section-2-Container .container .row2 .rightSide img {
        height: 122.4px;
    }

    .section-2-Container .container .row2 .leftSide .leftSide-header-h1 {
        font-size: 1.9rem;
    }

    .section-2-Container .container .row2 .leftSide .leftSide-header-p {
        font-size: 1.2rem;
    }

    .section-2-Container .container .row1 .rightSide .rightSide-content .rightSide-content-item img {
        width: auto;
        height: 6px;
    }

    .section-2-Container .container .row2 .leftSide .leftSide-content .leftSide-content-item img {
        width: auto;
        height: 6px;
    }

    .section-2-Container .container .row1 .rightSide .rightSide-header {
        
        margin-left: 2.9rem;
    }
}

@media (min-width: 500px) and (max-width: 600px) {
    .section-2-Container .container .row1 .leftSide img {
        height: 137.2px;
    }

    .section-2-Container .container .row2 .rightSide img {
        height: 148.4px;
    }

    .section-2-Container .container .row1 .rightSide .rightSide-content .rightSide-content-item img {
        width: auto;
        height: 8px;
    }

    .section-2-Container .container .row2 .leftSide .leftSide-content .leftSide-content-item img {
        width: auto;
        height: 8px;
    }

    .section-2-Container .container .row1 .rightSide .rightSide-header {
        
        margin-left: 2.9rem;
    }
}

@media (min-width: 600px) and (max-width: 700px) {
    .section-2-Container .container .row1 .leftSide img {
        height: 154.2px;
    }

    .section-2-Container .container .row2 .rightSide img {
        height: 165.4px;
    }

    .section-2-Container .container .row1 .rightSide .rightSide-content .rightSide-content-item img {
        width: auto;
        height: 8px;
    }

    .section-2-Container .container .row2 .leftSide .leftSide-content .leftSide-content-item img {
        width: auto;
        height: 8px;
    }
    .section-2-Container .container .row1 .rightSide .rightSide-header {
        
        margin-left: 2.9rem;
    }
}

@media (min-width: 700px) and (max-width: 800px) {
    .section-2-Container .container .row1 .leftSide img {
        height: 176.2px;
    }

    .section-2-Container .container .row2 .rightSide img {
        height: 202.4px;
    }

    .section-2-Container .container .row1 .rightSide .rightSide-content .rightSide-content-item img {
        width: auto;
        height: 10px;
    }

    .section-2-Container .container .row2 .leftSide .leftSide-content .leftSide-content-item img {
        width: auto;
        height: 10px;
    }

    .section-2-Container .container .row1 .rightSide .rightSide-header {
        
        margin-left: 2.9rem;
    }
}

@media (min-width: 800px) and (max-width: 900px) {
    .section-2-Container .container .row1 .leftSide img {
        height: 203.2px;
    }

    .section-2-Container .container .row2 .rightSide img {
        height: 227.4px;
    }

    .section-2-Container .container .row1 .rightSide .rightSide-content .rightSide-content-item img {
        width: auto;
        height: 10px;
    }

    .section-2-Container .container .row2 .leftSide .leftSide-content .leftSide-content-item img {
        width: auto;
        height: 10px;
    }

    .section-2-Container .container .row1 .rightSide .rightSide-header {
        
        margin-left: 2.7rem;
    }
}

@media (min-width: 900px) and (max-width: 1000px) {
    .section-2-Container .container .row1 .leftSide img {
        height: 224.2px;
    }

    .section-2-Container .container .row2 .rightSide img {
        height: 248.4px;
    }

    .section-2-Container .container .row1 .rightSide .rightSide-content .rightSide-content-item img {
        width: auto;
        height: 12px;
    }

    .section-2-Container .container .row2 .leftSide .leftSide-content .leftSide-content-item img {
        width: auto;
        height: 12px;
    }

    .section-2-Container .container .row1 .rightSide .rightSide-header {
        
        margin-left: 2.9rem;
    }
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .section-2-Container .container .row1 .leftSide img {
        height: 272.2px;
    }

    .section-2-Container .container .row2 .rightSide img {
        height: 299.4px;
    }

    .section-2-Container .container .row1 .rightSide .rightSide-content .rightSide-content-item img {
        width: auto;
        height: 16px;
    }

    .section-2-Container .container .row2 .leftSide .leftSide-content .leftSide-content-item img {
        width: auto;
        height: 16px;
    }
    .section-2-Container .container .row1 .rightSide .rightSide-header {
        
        margin-left: 2.9rem;
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .section-2-Container .container .row1 .leftSide img {
        height: 309.2px;
    }

    .section-2-Container .container .row2 .rightSide img {
        height: 350.4px;
    }

    .section-2-Container .container .row1 .rightSide .rightSide-content .rightSide-content-item img {
        width: auto;
        height: 22px;
    }

    .section-2-Container .container .row2 .leftSide .leftSide-content .leftSide-content-item img {
        width: auto;
        height: 22px;
    }

    .section-2-Container .container .row1 .rightSide .rightSide-header {
        
        margin-left: 3.4rem;
    }
}

@media (min-width: 1500px) and (max-width: 2000px) {
    .section-2-Container .container .row1 .leftSide img {
        height: 386.2px;
    }

    .section-2-Container .container .row2 .rightSide img {
        height: 397.4px;
    }

    .section-2-Container .container .row1 .rightSide .rightSide-content .rightSide-content-item img {
        width: auto;
        height: 22px;
    }

    .section-2-Container .container .row2 .leftSide .leftSide-content .leftSide-content-item img {
        width: auto;
        height: 22px;
    }

    .section-2-Container .container .row1 .rightSide .rightSide-header {
        
        margin-left: 2.9rem;
    }
}
