@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  width: 100%;

  
}

html{
  font-size: 16px;
}


.homePageContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

}

@media (min-width: 100px) and (max-width: 400px) {
  html{
    font-size: 3.2px !important;
  }
}

@media (min-width: 400px) and (max-width: 500px) {
  html{
    font-size: 4px !important;
  }
}

@media (min-width: 500px) and (max-width: 600px) {
  html{
    font-size: 4.8px !important;
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  html{
    font-size: 6.4px !important;
  }
}

@media (min-width: 800px) and (max-width: 900px) {
  html{
    font-size: 7.2px !important;
  }
}

@media (min-width: 900px) and (max-width: 1000px) {
  html{
    font-size: 8px !important;
  }
}

@media (min-width: 1000px) and (max-width: 1100px) {
  html{
    font-size: 8.8px !important;
  }
}

@media (min-width: 1100px) and (max-width: 1200px) {
  html{
    font-size: 9.6px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  html{
    font-size: 11.2px !important;
  }
}

@media (min-width: 1300px) and (max-width: 1400px) {
  html{
    font-size: 12px !important;
  }
}

@media (min-width: 1400px) and (max-width: 2000px) {
  html{
    font-size: 14.4px !important;
  }
}

