.section1 {
    width: 100%;
    height: 65rem;
    display: grid;
    place-items: center;
    background: linear-gradient(180deg, rgba(255, 148, 78, 0.1) 3%, rgba(255, 166, 131, 0.1) 100%);


}

.section1 .section-1-container {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;


}

.section1 .section-1-container .sectionContent {
    width: 100%;
    height: 32.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section1 .section-1-container .sectionContent .imageContainer {
    width: 50rem;
    height: 100%;
    position: relative;
    display: grid;
    place-items: center;
}

.section1 .section-1-container .sectionContent .imageContainer .dynamicImage {
    width: auto;
    position: absolute;
    bottom: 0;
    right: 0;
}

.section1 .section-1-container .sectionContent .imageContainer .box {
    position: absolute;
    top: 16.2rem;
    left: -1.75rem;
    z-index: 3;
    border-radius: 1.25rem;
    width: 17.125rem;
    height: 5rem;
    background-color: black;
    color: white;
    display: flex;
    padding-left: 2.5rem;
    padding-top: 1.25rem;
    padding-bottom: 0.625rem;
    gap: 1.25rem;
}

.section1 .section-1-container .sectionContent .imageContainer .box .icon {
    font-size: 1.375rem;
    transform: translateY(-10%);
}

.section1 .section-1-container .sectionContent .imageContainer .box .content {
    display: flex !important;
    flex-direction: column;
    gap: 1.25rem;
}

.section1 .section-1-container .sectionContent .imageContainer .box .content .limit {
    font-family: Inter;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 0.875rem;
    text-align: left;
}

.section1 .section-1-container .sectionContent .imageContainer .box .content .title {
    font-family: Inter;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 0.875rem;
    text-align: left;
}

.section1 .section-1-container .sectionContent .imageContainer .image1 {
    position: absolute;
    bottom: 0;
    left: 4.625rem;
    z-index: 1;
    width: 9.25rem;
    height: 9.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section1 .section-1-container .sectionContent .imageContainer .image1 img {
    width: auto;
    height: 148px;
}

.section1 .section-1-container .sectionContent .imageContainer .image1 p {
    text-wrap: nowrap;
    font-size: 1rem;
}

.section1 .section-1-container .sectionContent .imageContainer .image2 {
    position: absolute;
    bottom: 0;
    right: 4.625rem;
    z-index: 1;
    width: 9.25rem;
    height: 9.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section1 .section-1-container .sectionContent .imageContainer .image2 img {
    width: auto;
    height: 148px;
}

.section1 .section-1-container .sectionContent .imageContainer .image2 p {
    text-wrap: nowrap;
    font-size: 1rem;
}

.section1 .section-1-container .sectionContent .imageContainer .image3 {
    position: absolute;
    top: 0;
    left: 3.625rem;
    z-index: 1;
    width: 9.25rem;
    height: 9.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section1 .section-1-container .sectionContent .imageContainer .image3 img {
    width: auto;
    height: 148px;
}

.section1 .section-1-container .sectionContent .imageContainer .image3 p {
    text-wrap: nowrap;
    font-size: 1rem;
}

.section1 .section-1-container .sectionContent .imageContainer .image4 {
    position: absolute;
    top: 0;
    right: 4.625rem;
    z-index: 1;
    width: 9.25rem;
    height: 9.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section1 .section-1-container .sectionContent .imageContainer .image4 img {
    width: auto;
    height: 148px;
}

.section1 .section-1-container .sectionContent .imageContainer .image4 p {
    text-wrap: nowrap;
    font-size: 1rem;
}

.section1 .section-1-container .sectionContent .imageContainer .ellipse1 {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 100%;
    height: 100%;
    gap: 0;
    border: 0.04625rem dashed #FF9F60;
    border-radius: 50%;
}

.section1 .section-1-container .sectionContent .imageContainer .ellipse2 {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 70%;
    height: 70%;
    gap: 0;
    border: 0.04625rem dashed #FF9F60;
    border-radius: 50%;
}

.section1 .section-1-container .sectionContent .imageContainer .circle-mask {
    position: relative;
    bottom: 0;
    right: 0;
    width: 35.625rem;
    transform: translateY(-12%);
    height: 34rem;
    overflow: hidden;
}

.section1 .section-1-container .sectionContent .imageContainer .circle-mask .image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
}

.section1 .section-1-container .sectionContent .imageContainer .circle-mask .image img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 536px;
}


.section1 .section-1-container .sectionContent .GrowthKeLiyeExpertAdvice .para {
    text-align: unset;
    text-wrap: unset;
}

.section1 .section-1-container .sectionContent .leftContent {
    width: 65.25rem;
    height: 25.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2.5rem;
    padding: 0.625rem;
}

.section1 .section-1-container .sectionContent .leftContent .leftContent-header {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.section1 .section-1-container .sectionContent .leftContent .leftContent-header .heading {
    font-family: Inter;
    font-weight: 700;
}

.section1 .section-1-container .sectionContent .leftContent .leftContent-header .para {
    font-family: Open Sans;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2.25rem;
    text-align: left;
}

.section1 .section-1-container .sectionContent .leftContent .col4 {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1.25rem;
}

.section1 .section-1-container .sectionContent .leftContent .col4 b {
    font-family: Open Sans;
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 2.578125rem;
    text-align: left;
}

.section1 .section-1-container .sectionContent .leftContent .col4 p {
    font-family: Open Sans;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 2.109375rem;
    text-align: left;
    text-wrap: nowrap;
}

.section1 .section-1-container .sectionContent .leftContent .col4 .row2,
.section1 .section-1-container .sectionContent .leftContent .col4 .row3 {
    border-left: 0.0625rem solid grey;
    padding-left: 0.625rem;
}




.section1 .section-1-container .informationBox {
    position: absolute;
    top: 42rem;
    /* 100% of 100vh */
    right: 0;
    border-radius: 0.625rem;
    /* 10px */
    width: 100%;
    /* 1300px */
    height: 9.275rem;
    /* 15vh */
    background: linear-gradient(90deg, #FF9F60 0%, rgba(255, 158, 94, 0.18) 100%);
    padding: 0.625rem;
    /* 10px */
    z-index: 4;


}

.section1 .section-1-container .informationBox .content {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 0.625rem;
    /* 10px */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 16rem;


}

.section1 .section-1-container .informationBox .content .link {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    /* 10px */
    margin-left: 4.375rem;
    /* 70px */




}

.section1 .section-1-container .informationBox .content .link .icon {
    width: 1.8rem;
    height: 1.8rem;

}

.section1 .section-1-container .informationBox .content .link .icon img {
    width: 100%;
    height: 100%;
}

.section1 .section-1-container .informationBox .content .link .about {
    display: flex;
    flex-direction: column;
    gap: 0.3125rem;
    /* 5px */
}

.section1 .section-1-container .informationBox .content .link .about p:nth-child(1) {
    font-family: Inter;
    font-size: 1.43375rem;
    font-weight: 900;
    text-wrap: nowrap;
}

.section1 .section-1-container .informationBox .content .link .about p:nth-child(2) {
    font-family: Inter;
    font-size: 1.083125rem;
    font-weight: 500;
    color: rgba(100, 116, 139, 1);
    text-wrap: nowrap;
}


.section1 .section-1-container .col5 {
    position: absolute;
    top: 36rem;
    left: 0.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1.25rem;
    z-index: 5;








}

.section1 .section-1-container .col5 button {
    text-transform: uppercase;
}

.section1 .section-1-container .col5 .applyButton {
    width: 11.75rem;
    height: 3rem;
    display: grid;
    place-items: center;
    text-transform: uppercase;

    gap: 0;
    border: none;

    background: #FF944E;
    color: white;
    font-family: Inter;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.3rem;
}

.section1 .section-1-container .col5 .getStarted {
    width: 11.75rem;
    height: 3rem;

    gap: 0;
    border: none;
    display: grid;
    place-items: center;

    background: #FF944E;
    color: white;
    font-family: Inter;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.3rem;
    text-transform: uppercase;

}

.section1 .section-1-container .col5 .ourProducts {
    width: 11.630625rem;
    /* 186.09px */
    height: 2.899375rem;
    /* 46.39px */
    color: #FF944E;
    cursor: pointer;
    text-decoration: none;

    text-transform: uppercase;
    display: grid;
    place-items: center;
    top: 0.0625rem;
    /* 1px */
    gap: 0;
    background: #FFFFFF;
    border: 0.0625rem solid #FF944E;
    /* 1px */
    font-family: Inter;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.3rem;
}


.section1 .InvoiceDiscountingSection .sectionContent .leftContent {

    width: 51.25rem;


}





.section1 .GrowthKeLiyeExpertAdviceSection .sectionContent {
    height: 50rem;
    align-items: unset;
}

.section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent {
    width: 40rem;
    margin-top: 4%;
}

.section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .heading {
    font-size: 4.5rem !important;
}

.section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .para {
    font-size: 1.3rem !important;
}

.section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer {
    width: 50rem;
    height: 100%;
}

.section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .dynamicImage {
    transition: 0.5s all ease;
}








.section1 .GrowthKeLiyeExpertAdviceSection .col5 {
    top: 50rem;
}

.section1 .PurchaseFinanceSection .sectionContent .imageContainer .dynamicImage {
    width: auto;
    height: 562.08px;
}

.section1 .LeaseRentalDiscountingSection .col5 {
    top: 34rem;
}

.section1 .WorkingCapitalSection .sectionContent .imageContainer .dynamicImage {
    height: 588.08px;

    bottom: -5rem;
    right: -1rem;
}

@media (min-width: 100px) and (max-width: 400px) {




    .section1 {
        display: unset;
        place-items: unset;
        height: 109rem;
    }

    .section1 .section-1-container {
        width: 100%;
    }

    .section1 .section-1-container .informationBox {
        width: 100%;
        top: 82rem;
    }

    .section1 .section-1-container .informationBox .content {
        justify-content: space-between;
        padding: 10px;
        padding-right: 18rem;
    }

    .section1 .section-1-container .informationBox .content .link {
        gap: 2px;
        margin-left: 0;
    }

    .section1 .section-1-container .informationBox .content .link .icon {
        width: 2rem;
        height: 2rem;
    }

    .section1 .section-1-container .informationBox .content .link .icon img {
        width: 100%;
        height: 100%;
    }

    .section1 .section-1-container .col5 {
        top: 92%;
        left: 0%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
    }

    .section1 .section-1-container .col5 .getStarted {
        width: 15.75rem;
        height: 5rem;
        font-size: 2rem;
    }

    .section1 .section-1-container .col5 .applyButton {
        width: 15.75rem;
        height: 5rem;
        font-size: 2rem;
    }

    .section1 .section-1-container .col5 .ourProducts {
        width: 19.75rem;
        height: 5rem;
        font-size: 1.8rem;
    }


    .section1 .section-1-container .sectionContent {
        flex-direction: column;
        justify-content: unset;
        height: 77.75rem;
        /* gap: 60px; */
    }

    .section1 .section-1-container .sectionContent .leftContent {
        width: 100%;
        height: 58%;
        align-items: center;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .heading {
        width: 100%;
        font-size: 6.5rem !important;
        line-height: 5.4rem !important;
        text-align: center;
        text-wrap: balance;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .para {
        font-size: 2.2rem;
        line-height: 5.3rem;
        text-align: center;
        text-wrap: balance;
    }

    .section1 .section-1-container .sectionContent .leftContent .col4 {
        align-items: center;
        justify-content: center;
    }

    .section1 .section-1-container .sectionContent .imageContainer {
        width: 100%;
        height: 0;
    }

    .section1 .section-1-container .sectionContent .imageContainer .dynamicImage {
        inset: 0;
        margin: auto;
    }

    .section1 .section-1-container .sectionContent .imageContainer .box {
        top: 12.05rem;
        left: -2.75rem;
    }

    .section1 .section-1-container .sectionContent .imageContainer .image1 {
        left: 4.625rem;
    }

    .section1 .section-1-container .sectionContent .imageContainer .image2 {
        right: 4.625rem;
    }

    .section1 .section-1-container .sectionContent .imageContainer .image3 {
        left: 4.625rem;
    }

    .section1 .section-1-container .sectionContent .imageContainer .image4 {
        right: 4.625rem;
    }


    .section1 .section-1-container .BusinessLoan {
        height: 81.75rem;
    }

    .section1 .section-1-container .PurchaseFinance {
        height: 71.75rem;


    }

    .section1 .section-1-container .PurchaseFinance .imageContainer .dynamicImage {
        height: 199.08px !important;

    }




    .section1 .GrowthKeLiyeExpertAdviceSection .col5 {
        top: 100rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent {
        height: 75.75rem;
        align-items: center;
        gap: 2rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent {
        width: 100%;
        height: 35%;
        margin-top: 0;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .heading {
        font-size: 4.5rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.3rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer {
        width: 50rem;
        height: 70%;
        margin-top: 0;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .box {
        top: 16.2rem;
        left: -1.75rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image1 {
        left: 4.625rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image2 {
        right: 4.625rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image3 {
        left: 4.625rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image4 {
        right: 4.625rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image1 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image2 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image3 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image4 img {
        height: 30px;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask {
        width: 35.625rem;
        height: 34rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask .image img {
        height: 105px;
    }



    .section1 .SecuredLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 175px !important;
    }

    .section1 .BusinessLoanSection .sectionContent .imageContainer .dynamicImage {
        inset: 0;
        margin: auto;
        height: 159px !important;
    }

    .section1 .LeaseRentalDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 174px !important;
    }

    .section1 .InvoiceDiscountingSection .sectionContent {
        height: 84.9rem;
    }

    .section1 .InvoiceDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 147px !important;
    }



    .section1 .WorkingCapitalSection .sectionContent .imageContainer .dynamicImage {
        height: 199px !important;
        bottom: -14rem;
        right: 0 !important;
    }




}


@media (min-width: 400px) and (max-width: 500px) {




    .section1 {
        display: unset;
        place-items: unset;
        height: 109rem;
    }

    .section1 .section-1-container {
        width: 100%;
    }

    .section1 .section-1-container .informationBox {
        width: 100%;
        top: 82rem;
    }

    .section1 .section-1-container .informationBox .content {
        justify-content: space-between;
        padding: 10px;
        padding-right: 18rem;
    }

    .section1 .section-1-container .informationBox .content .link {
        gap: 2px;
        margin-left: 0;
    }

    .section1 .section-1-container .informationBox .content .link .icon {
        width: 2rem;
        height: 2rem;
    }

    .section1 .section-1-container .informationBox .content .link .icon img {
        width: 100%;
        height: 100%;
    }

    .section1 .section-1-container .col5 {
        top: 92%;
        left: 0%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
    }

    .section1 .section-1-container .col5 .getStarted {
        width: 15.75rem;
        height: 5rem;
        font-size: 2rem;
    }

    .section1 .section-1-container .col5 .applyButton {
        width: 15.75rem;
        height: 5rem;
        font-size: 2rem;
    }

    .section1 .section-1-container .col5 .ourProducts {
        width: 19.75rem;
        height: 5rem;
        font-size: 1.8rem;
    }


    .section1 .section-1-container .sectionContent {
        flex-direction: column;
        justify-content: unset;
        height: 80.75rem;
    }

    .section1 .section-1-container .sectionContent .leftContent {
        width: 100%;
        height: 58%;
        align-items: center;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .heading {
        width: 100%;
        font-size: 6.5rem !important;
        line-height: 5.4rem !important;
        text-align: center;
        text-wrap: balance;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .para {
        font-size: 2.2rem;
        line-height: 5.3rem;
        text-align: center;
        text-wrap: balance;
    }

    .section1 .section-1-container .sectionContent .leftContent .col4 {
        align-items: center;
        justify-content: center;
    }

    .section1 .section-1-container .sectionContent .imageContainer {
        width: 100%;
        height: 0;
    }

    .section1 .section-1-container .sectionContent .imageContainer .dynamicImage {
        inset: 0;
        margin: auto;
    }

    .section1 .section-1-container .sectionContent .imageContainer .box {
        top: 12.05rem;
        left: -2.75rem;
    }

    .section1 .section-1-container .sectionContent .imageContainer .image1 {
        left: 4.625rem;
    }

    .section1 .section-1-container .sectionContent .image2 {
        right: 4.625rem;
    }

    .section1 .section-1-container .sectionContent .image3 {
        left: 4.625rem;
    }

    .section1 .section-1-container .sectionContent .image4 {
        right: 4.625rem;
    }


    .section1 .section-1-container .BusinessLoan {
        height: 85.75rem;
    }

    .section1 .section-1-container .PurchaseFinance {
        height: 75.75rem;
    }

    .section1 .section-1-container .PurchaseFinance .imageContainer .dynamicImage {
        height: 248.08px !important;
    }





    .section1 .GrowthKeLiyeExpertAdviceSection .col5 {
        top: 100rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent {
        height: 75.75rem;
        align-items: center;
        gap: 2rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent {
        width: 100%;
        height: 35%;
        margin-top: 0;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .heading {
        font-size: 4.5rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.3rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer {
        width: 50rem;
        height: 70%;
        margin-top: 0;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .box {
        top: 16.2rem;
        left: -1.75rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image1 {
        left: 4.625rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image2 {
        right: 4.625rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image3 {
        left: 4.625rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image4 {
        right: 4.625rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image1 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image2 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image3 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image4 img {
        height: 43px;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask {
        width: 35.625rem;
        height: 34rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask .image img {
        height: 131px;
    }



    .section1 .SecuredLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 226px !important;
    }



    .section1 .BusinessLoanSection .sectionContent .imageContainer .dynamicImage {
        inset: 0;
        margin: auto;
        height: 202px !important;
    }


    .section1 .LeaseRentalDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 225px !important;
    }


    .section1 .InvoiceDiscountingSection .sectionContent {
        height: 87.9rem;
    }

    .section1 .InvoiceDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 192px !important;
    }



    .section1 .WorkingCapitalSection .sectionContent .imageContainer .dynamicImage {
        height: 240px !important;
        bottom: -14rem;
        right: 0 !important;
    }




}





@media (min-width: 500px) and (max-width: 600px) {
    html {
        font-size: 4.8px !important;
    }



    .section1 {
        display: unset;
        place-items: unset;
        height: 109rem;
    }

    .section1 .section-1-container {
        width: 100%;
    }

    .section1 .section-1-container .informationBox {
        width: 100%;
        top: 82rem;
    }

    .section1 .section-1-container .informationBox .content {
        justify-content: space-between;
        padding: 10px;
        padding-right: 18rem;
    }

    .section1 .section-1-container .informationBox .content .link {
        gap: 2px;
        margin-left: 0;
    }

    .section1 .section-1-container .informationBox .content .link .icon {
        width: 2rem;
        height: 2rem;
    }

    .section1 .section-1-container .informationBox .content .link .icon img {
        width: 100%;
        height: 100%;
    }

    .section1 .section-1-container .col5 {
        top: 92%;
        left: 0%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
    }

    .section1 .section-1-container .col5 .getStarted,
    .section1 .section-1-container .col5 .applyButton {
        width: 15.75rem;
        height: 5rem;
        font-size: 2rem;
    }

    .section1 .section-1-container .col5 .ourProducts {
        width: 19.75rem;
        height: 5rem;
        font-size: 1.8rem;
    }


    .section1 .section-1-container .sectionContent {
        flex-direction: column;
        justify-content: unset;
        height: 79.75rem;
        /* gap: 60px; */
        align-items: center;
    }

    .section1 .section-1-container .sectionContent .leftContent {
        width: 100%;
        height: 58%;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .heading {
        width: 100%;
        font-size: 6.5rem !important;
        line-height: 5.4rem !important;
        text-align: center;
        text-wrap: balance;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .para {
        font-size: 2.2rem;
        line-height: 20px;
        text-align: center;
        text-wrap: balance;
    }

    .section1 .section-1-container .sectionContent .leftContent .col4 {
        align-items: center;
        justify-content: center;
    }

    .section1 .section-1-container .sectionContent .imageContainer {
        width: 100%;
        height: 0;
    }

    .section1 .section-1-container .sectionContent .imageContainer .dynamicImage {
        inset: 0;
        margin: auto;
    }

    .section1 .section-1-container .sectionContent .imageContainer .box {
        top: 12.05rem;
        left: -2.75rem;
    }


    .section1 .section-1-container .BusinessLoan {
        height: 85.75rem;
    }

    .section1 .section-1-container .PurchaseFinance {
        height: 75.75rem;


    }

    .section1 .section-1-container .PurchaseFinance .imageContainer .dynamicImage {
        height: 307px !important;

    }


    .section1 .WorkingCapitalSection .sectionContent .imageContainer .dynamicImage {
        height: 364px !important;
        bottom: -6rem;
        right: 0 !important;
    }


    .section1 .InvoiceDiscountingSection .sectionContent {
        height: 89.9rem;
    }

    .section1 .InvoiceDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 230px !important;
    }



    .section1 .SecuredLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 285px !important;
    }




    .section1 .section-1-container .GrowthKeLiyeExpertAdvice .leftContent .col4 {
        margin-top: 4%;
    }



    .section1 .GrowthKeLiyeExpertAdviceSection .col5 {
        top: 100rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent {
        height: 82.75rem;
        gap: 2rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent {
        align-items: center;
        width: 100%;
        height: 35%;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .heading {
        font-size: 4.5rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.3rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer {
        width: 50rem;
        height: 70%;
        margin-top: 0;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .box {
        top: 16.2rem;
        left: -1.75rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image1 {
        left: 4.625rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image2 {
        right: 4.625rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image3 {
        left: 4.625rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image4 {
        right: 4.625rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image1 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image2 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image3 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image4 img {
        height: 54px;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask {
        width: 35.625rem;
        height: 34rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask .image img {
        height: 159px;
    }





    .section1 .BusinessLoanSection .sectionContent .imageContainer .dynamicImage {
        inset: 0;
        margin: auto;
        height: 252px !important;
        /* right: -10rem; */
    }


    .section1 .LeaseRentalDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 285px !important;
    }











}

@media (min-width: 600px) and (max-width: 700px) {
    html {
        font-size: 4.8px !important;
    }

    .section1 .section-1-container .sectionContent {
        width: 100%;
        height: 33.05rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .section1 .section-1-container .sectionContent .leftContent {
        width: 59rem;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .heading {
        font-size: 6rem !important;
        line-height: 7rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .para {
        font-size: 2rem;
    }

    .section1 .section-1-container .informationBox {
        top: 43rem !important;
    }


    .section1 .section-1-container .PurchaseFinance .imageContainer .dynamicImage {
        height: 166px !important;

    }


    .section1 .GrowthKeLiyeExpertAdviceSection .col5 {
        top: 47rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent {
        height: 50rem !important;
        align-items: unset;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent {
        width: 40rem;
        margin-top: 4%;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .heading {
        font-size: 4.5rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.3rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer {
        width: 50rem;
        height: 100%;
        margin-top: 0;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .box {
        top: 16.2rem;
        left: -1.75rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image1 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image2 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image3 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image4 img {
        height: 54px;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask {
        width: 35.625rem;
        height: 34rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask .image img {
        height: 157px;
    }


    .section1 .WorkingCapitalSection .sectionContent .imageContainer .dynamicImage {
        height: 182px !important;
        right: 0 !important;
    }

    .section1 .InvoiceDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 106px !important;
    }



    .section1 .SecuredLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 147px !important;
        right: -6.625rem !important;
    }



    .section1 .BusinessLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 128px !important;
    }


    .section1 .LeaseRentalDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 157px !important;
    }


}

@media (min-width: 700px) and (max-width: 800px) {
    html {
        font-size: 6.4px !important;
    }

    .section1 .section-1-container .sectionContent {
        width: 100%;
        height: 33.05rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .heading {
        font-size: 5rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.4rem !important;
    }

    .section1 .section-1-container .informationBox {
        top: 41rem;
    }


    .section1 .section-1-container .PurchaseFinance .imageContainer .dynamicImage {
        height: 215px !important;

    }


    .section1 .GrowthKeLiyeExpertAdviceSection .col5 {
        top: 47rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent {
        height: 50rem !important;
        align-items: unset;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent {
        width: 32rem;
        margin-top: 4%;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .heading {
        font-size: 3.5rem !important;
        line-height: 4rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .para {
        font-size: 1rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer {
        width: 50rem;
        height: 100%;
        margin-top: 0;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .box {
        top: 16.2rem;
        left: -1.75rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image1 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image2 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image3 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image4 img {
        height: 73px;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask {
        width: 35.625rem;
        height: 34rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask .image img {
        height: 209px;
    }



    .section1 .WorkingCapitalSection .sectionContent .imageContainer .dynamicImage {
        height: 215px !important;
        right: 0 !important;
    }

    .section1 .InvoiceDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 138px !important;
    }



    .section1 .SecuredLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 174px !important;
        right: -5.625rem !important;
    }



    .section1 .BusinessLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 144px !important;
    }


    .section1 .LeaseRentalDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 175px !important;
    }


}


@media (min-width: 800px) and (max-width: 900px) {


    .section1 .SecuredLoanSection .sectionContent .imageContainer .dynamicImage {
        right: -3.825rem;


    }



    .section1 .section-1-container .informationBox {
        top: 41rem;
    }

    .section1 .section-1-container .sectionContent {
        height: 34rem;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .heading {
        font-size: 5rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.8rem !important;
    }


    .section1 .section-1-container .PurchaseFinance .imageContainer .dynamicImage {
        height: 241.08px !important;

    }




    .section1 .GrowthKeLiyeExpertAdviceSection .col5 {
        top: 47rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent {
        height: 50rem !important;
        align-items: unset;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent {
        width: 32rem;
        margin-top: 4%;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .heading {
        font-size: 3.5rem !important;
        line-height: 5rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .para {
        font-size: 1rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer {
        width: 50rem;
        height: 100%;
        margin-top: 0;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .box {
        top: 16.2rem;
        left: -1.75rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image1 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image2 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image3 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image4 img {
        height: 77px;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask {
        width: 35.625rem;
        height: 34rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask .image img {
        height: 241px;
    }



    .section1 .WorkingCapitalSection .sectionContent .imageContainer .dynamicImage {
        height: 266px !important;
        right: 0 !important;
    }

    .section1 .InvoiceDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 164px !important;
    }



    .section1 .SecuredLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 193px !important;
        right: -5.625rem !important;
    }



    .section1 .BusinessLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 173px !important;
    }


    .section1 .LeaseRentalDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 213px !important;
    }


}


@media (min-width: 900px) and (max-width: 1000px) {



    .section1 .SecuredLoanSection .sectionContent .imageContainer .dynamicImage {
        right: -3.825rem;
    }

    .section1 .SecuredLoanSection .sectionContent .imageContainer .dynamicImage img:nth-child(1) {
        width: 55.5rem;
        height: 37.5rem;
    }



    .section1 .section-1-container .PurchaseFinance .imageContainer .dynamicImage {
        height: 270.08px !important;

    }




    .section1 .section-1-container .informationBox {
        top: 41rem;
    }

    .section1 .section-1-container .sectionContent {
        height: 34.35rem;
    }

    .section1 .section-1-container .sectionContent .leftContent {
        width: 46rem;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .heading {
        font-size: 5rem !important;
        line-height: 7rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .para {
        font-size: 2rem !important;
    }






    .section1 .GrowthKeLiyeExpertAdviceSection .col5 {
        top: 47rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent {
        height: 50rem !important;
        align-items: unset;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent {
        width: 32rem;
        margin-top: 4%;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .heading {
        font-size: 3.5rem !important;
        line-height: 5rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .para {
        font-size: 1rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer {
        width: 50rem;
        height: 100%;
        margin-top: 0;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .box {
        top: 16.2rem;
        left: -1.75rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image1 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image2 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image3 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image4 img {
        height: 77px;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask {
        width: 35.625rem;
        height: 34rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask .image img {
        height: 266px;
    }


    .section1 .WorkingCapitalSection .sectionContent .imageContainer .dynamicImage {
        height: 301px !important;
        right: 0 !important;
    }

    .section1 .InvoiceDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 185px !important;
    }



    .section1 .SecuredLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 230px !important;
        right: -5.625rem !important;
    }



    .section1 .BusinessLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 206px !important;
    }


    .section1 .LeaseRentalDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 245px !important;
    }


}



@media (min-width: 1000px) and (max-width: 1100px) {


    .section1 .section-1-container .sectionContent {
        height: 35.2rem !important;
    }

    .section1 .section-1-container .sectionContent .imageContainer .informationBox {
        top: 36rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent {
        width: 53.25rem;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .heading {
        font-size: 5rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.6rem !important;
    }


    .section1 .section-1-container .PurchaseFinance .imageContainer .dynamicImage {
        height: 310.08px !important;

    }


    .section1 .GrowthKeLiyeExpertAdviceSection .col5 {
        top: 47rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent {
        height: 50rem !important;
        align-items: unset;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent {
        width: 32rem;
        margin-top: 4%;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .heading {
        font-size: 3.5rem !important;
        line-height: 5rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .para {
        font-size: 1rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer {
        width: 50rem;
        height: 100%;
        margin-top: 0;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .box {
        top: 16.2rem;
        left: -1.75rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image1 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image2 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image3 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image4 img {
        height: 100px;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask {
        width: 35.625rem;
        height: 34rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask .image img {
        height: 293px;
    }


    .section1 .WorkingCapitalSection .sectionContent .imageContainer .dynamicImage {
        height: 345px !important;
        right: 0 !important;
        bottom: -6rem;
    }

    .section1 .InvoiceDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 211px !important;
    }



    .section1 .SecuredLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 271px !important;
        right: -6rem !important;
    }



    .section1 .BusinessLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 228px !important;
    }


    .section1 .LeaseRentalDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 287px !important;
    }

}



@media (min-width: 1100px) and (max-width: 1200px) {


    .section1 .section-1-container .sectionContent {
        height: 36.2rem !important;
    }

    .section1 .section-1-container .sectionContent .imageContainer .informationBox {
        top: 36rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent {
        width: 53.25rem;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .heading {
        font-size: 5rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.6rem !important;
    }


    .section1 .section-1-container .PurchaseFinance .imageContainer .dynamicImage {
        height: 350.08px !important;

    }


    .section1 .GrowthKeLiyeExpertAdviceSection .col5 {
        top: 47rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent {
        height: 50rem !important;
        align-items: unset;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent {
        width: 32rem;
        margin-top: 4%;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .heading {
        font-size: 3.5rem !important;
        line-height: 5rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .para {
        font-size: 1rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer {
        width: 50rem;
        height: 100%;
        margin-top: 0;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .box {
        top: 16.2rem;
        left: -1.75rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image1 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image2 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image3 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image4 img {
        height: 100px;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask {
        width: 35.625rem;
        height: 34rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask .image img {
        height: 314px;
    }


    .section1 .WorkingCapitalSection .sectionContent .imageContainer .dynamicImage {
        height: 375px !important;
        right: 0 !important;
        bottom: -5.5rem;
    }

    .section1 .InvoiceDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 229px !important;
    }



    .section1 .SecuredLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 294px !important;
        right: -6rem !important;
    }



    .section1 .BusinessLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 255px !important;
    }


    .section1 .LeaseRentalDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 305px !important;
    }

}



@media (min-width: 1200px) and (max-width: 1400px) {
    html {
        font-size: 10.4px !important;
    }

    .section1 .section-1-container .sectionContent {
        height: 36rem !important;
    }

    .section1 .section-1-container .sectionContent .imageContainer .informationBox {
        top: 36rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent {
        width: 56.25rem;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .heading {
        font-size: 5rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.3rem !important;
    }


    .section1 .section-1-container .PurchaseFinance .imageContainer .dynamicImage {
        height: 380.08px !important;

    }


    .section1 .GrowthKeLiyeExpertAdviceSection .col5 {
        top: 47rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent {
        height: 50rem !important;
        align-items: unset;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent {
        width: 40rem;
        margin-top: 4%;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .heading {
        font-size: 4.5rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.3rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer {
        width: 50rem;
        height: 100%;
        margin-top: 0;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .box {
        top: 16.2rem;
        left: -1.75rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image1 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image2 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image3 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image4 img {
        height: 106px;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask {
        width: 35.625rem;
        height: 34rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask .image img {
        height: 345px;
    }



    .section1 .WorkingCapitalSection .sectionContent .imageContainer .dynamicImage {
        height: 389px !important;
        right: 0 !important;
        bottom: -5.5rem;
    }

    .section1 .InvoiceDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 245px !important;
    }



    .section1 .SecuredLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 312px !important;
        right: -6rem !important;
    }



    .section1 .BusinessLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 266px !important;
    }


    .section1 .LeaseRentalDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 317px !important;
    }

}

@media (min-width: 1400px) and (max-width: 1500px) {
    html {
        font-size: 11.2px !important;
    }

    .section1 .section-1-container .sectionContent {
        height: 36rem !important;
    }

    .section1 .section-1-container .sectionContent .imageContainer .informationBox {
        top: 40rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent {
        width: 50.25rem;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .heading {
        font-size: 5.6rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.4rem !important;
    }



    .section1 .section-1-container .PurchaseFinance .imageContainer .dynamicImage {
        height: 402.08px !important;

    }


    .section1 .GrowthKeLiyeExpertAdviceSection .col5 {
        top: 47rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent {
        height: 50rem !important;
        align-items: unset;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent {
        width: 40rem;
        margin-top: 4%;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .heading {
        font-size: 4.5rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.3rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer {
        width: 50rem;
        height: 100%;
        margin-top: 0;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .box {
        top: 16.2rem;
        left: -1.75rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image1 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image2 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image3 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image4 img {
        height: 117px;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask {
        width: 35.625rem;
        height: 34rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask .image img {
        height: 368px;
    }



    .section1 .WorkingCapitalSection .sectionContent .imageContainer .dynamicImage {
        height: 451px !important;
        right: 0 !important;
        bottom: -6rem;
    }

    .section1 .InvoiceDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 282px !important;
    }



    .section1 .SecuredLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 357px !important;
        right: -7rem !important;
    }



    .section1 .BusinessLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 302px !important;
    }


    .section1 .LeaseRentalDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 330px !important;
    }

}


@media (min-width: 1500px) and (max-width: 1600px) {
    html {
        font-size: 12px !important;
    }

    .section1 .section-1-container .sectionContent {
        height: 36rem !important;
    }

    .section1 .section-1-container .sectionContent .imageContainer .informationBox {
        top: 36rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent {
        width: 54.25rem;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .heading {
        font-size: 5.7rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.4rem !important;
    }


    .section1 .section-1-container .PurchaseFinance .imageContainer .dynamicImage {
        height: 435.08px !important;

    }


    .section1 .GrowthKeLiyeExpertAdviceSection .col5 {
        top: 47rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent {
        height: 50rem !important;
        align-items: unset;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent {
        width: 40rem;
        margin-top: 4%;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .heading {
        font-size: 4.5rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.3rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer {
        width: 50rem;
        height: 100%;
        margin-top: 0;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .box {
        top: 16.2rem;
        left: -1.75rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image1 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image2 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image3 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image4 img {
        height: 124px;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask {
        width: 35.625rem;
        height: 34rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask .image img {
        height: 394px;
    }



    .section1 .WorkingCapitalSection .sectionContent .imageContainer .dynamicImage {
        height: 451px !important;
        right: 0 !important;
        bottom: -6rem;
    }

    .section1 .InvoiceDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 282px !important;
    }



    .section1 .SecuredLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 357px !important;
        right: -7rem !important;
    }



    .section1 .BusinessLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 302px !important;
    }


    .section1 .LeaseRentalDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 330px !important;
    }

}

@media (min-width: 1600px) and (max-width: 1700px) {
    html {
        font-size: 12.8px !important;
    }

    .section1 .section-1-container .sectionContent {
        height: 36rem !important;
    }

    .section1 .section-1-container .sectionContent .imageContainer .informationBox {
        top: 36rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent {
        width: 54.25rem;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .heading {
        font-size: 5.7rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.4rem !important;
    }


    .section1 .section-1-container .PurchaseFinance .imageContainer .dynamicImage {
        height: 472.08px !important;

    }


    .section1 .GrowthKeLiyeExpertAdviceSection .col5 {
        top: 47rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent {
        height: 50rem !important;
        align-items: unset;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent {
        width: 40rem;
        margin-top: 4%;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .heading {
        font-size: 4.5rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.3rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer {
        width: 50rem;
        height: 100%;
        margin-top: 0;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .box {
        top: 16.2rem;
        left: -1.75rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image1 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image2 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image3 img,
    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .image4 img {
        height: 124px;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask {
        width: 35.625rem;
        height: 34rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask .image img {
        height: 423px;
    }



    .section1 .WorkingCapitalSection .sectionContent .imageContainer .dynamicImage {
        height: 520px !important;
        right: 0 !important;
        bottom: -6rem;
    }

    .section1 .InvoiceDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 314px !important;
    }



    .section1 .SecuredLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 413px !important;
        right: -7rem !important;
    }



    .section1 .BusinessLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 345px !important;
    }


    .section1 .LeaseRentalDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 406px !important;
    }

}



@media (min-width: 1700px) and (max-width: 1800px) {

    html {
        font-size: 13.6px !important;
    }

    .section1 .section-1-container .sectionContent {
        height: 32rem !important;
    }

    .section1 .section-1-container .sectionContent .imageContainer .informationBox {
        top: 36rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent {
        width: 50rem;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .heading {
        font-size: 5.4rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.6rem !important;
    }


    .section1 .section-1-container .PurchaseFinance .imageContainer .dynamicImage {
        height: 492.08px !important;

    }


    .section1 .GrowthKeLiyeExpertAdviceSection .col5 {
        top: 47rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent {
        height: 50rem !important;
        align-items: unset;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent {
        width: 40rem;
        margin-top: 4%;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .heading {
        font-size: 4.5rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.3rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer {
        width: 50rem;
        height: 100%;
        margin-top: 0;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .box {
        top: 16.2rem;
        left: -1.75rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask {
        width: 35.625rem;
        height: 34rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask .image img {
        height: 449px;
    }


    .section1 .WorkingCapitalSection .sectionContent .imageContainer .dynamicImage {
        height: 520px !important;
        right: 0 !important;
        bottom: -6rem;
    }

    .section1 .InvoiceDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 314px !important;
    }



    .section1 .SecuredLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 413px !important;
        right: -7rem !important;
    }



    .section1 .BusinessLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 345px !important;
    }


    .section1 .LeaseRentalDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 406px !important;
    }

}



@media (min-width: 1800px) and (max-width: 1900px) {


    .section1 .section-1-container .sectionContent {
        height: 32rem !important;
    }

    .section1 .section-1-container .sectionContent .imageContainer .informationBox {
        top: 36rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent {
        width: 60rem;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .heading {
        font-size: 6rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.6rem !important;
    }


    .section1 .section-1-container .PurchaseFinance .imageContainer .dynamicImage {
        height: 492.08px !important;

    }


    .section1 .GrowthKeLiyeExpertAdviceSection .col5 {
        top: 47rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent {
        height: 50rem !important;
        align-items: unset;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent {
        width: 40rem;
        margin-top: 4%;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .heading {
        font-size: 4.5rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.3rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer {
        width: 50rem;
        height: 100%;
        margin-top: 0;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .box {
        top: 16.2rem;
        left: -1.75rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask {
        width: 35.625rem;
        height: 34rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask .image img {
        height: 482px;
    }



    .section1 .WorkingCapitalSection .sectionContent .imageContainer .dynamicImage {
        height: 520px !important;
        right: 0 !important;
        bottom: -6rem;
    }

    .section1 .InvoiceDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 314px !important;
    }



    .section1 .SecuredLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 413px !important;
        right: -7rem !important;
    }



    .section1 .BusinessLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 345px !important;
    }


    .section1 .LeaseRentalDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 406px !important;
    }

}


@media (min-width: 1900px) and (max-width: 2000px) {


    .section1 .section-1-container .sectionContent {
        height: 32.4rem !important;
    }

    .section1 .section-1-container .sectionContent .imageContainer .informationBox {
        top: 36rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent {
        width: 60rem;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .heading {
        font-size: 6.2rem !important;
    }

    .section1 .section-1-container .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.7rem !important;
    }


    .section1 .section-1-container .PurchaseFinance .imageContainer .dynamicImage {
        height: 492.08px !important;

    }


    .section1 .GrowthKeLiyeExpertAdviceSection .col5 {
        top: 47rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent {
        height: 50rem !important;
        align-items: unset;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent {
        width: 40rem;
        margin-top: 4%;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .heading {
        font-size: 4.5rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .leftContent .leftContent-header .para {
        font-size: 1.3rem !important;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer {
        width: 50rem;
        height: 100%;
        margin-top: 0;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .box {
        top: 16.2rem;
        left: -1.75rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask {
        width: 35.625rem;
        height: 34rem;
    }

    .section1 .GrowthKeLiyeExpertAdviceSection .sectionContent .imageContainer .circle-mask .image img {
        height: 482px;
    }


    .section1 .WorkingCapitalSection .sectionContent .imageContainer .dynamicImage {
        height: 520px !important;
        right: 0 !important;
        bottom: -6rem;
    }

    .section1 .InvoiceDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 314px !important;
    }



    .section1 .SecuredLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 413px !important;
        right: -7rem !important;
    }



    .section1 .BusinessLoanSection .sectionContent .imageContainer .dynamicImage {
        height: 345px !important;
    }


    .section1 .LeaseRentalDiscountingSection .sectionContent .imageContainer .dynamicImage {
        height: 406px !important;
    }

}