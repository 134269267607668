* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.navbarContainer {
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 8;
    padding: 3rem 5rem;
}

.logo {
    font-size: 7.5rem;
    color: #FE9450;
    position: relative;
    cursor: pointer;
    font-weight: 500;
}

.logo .logoLastWord {
    font-size: 3.5rem;
    color: black;
    position: absolute;
    bottom: 10%;
    left: 80%;


}

.navbarContainer .link {
    display: flex;
    align-items: center;
    gap: 30px;
}

.navbarContainer .link .link-list {
    display: flex;
    align-items: center;
    gap: 19px;
}

.navbarContainer .link .link-list .nav {
    height: 62px;
    list-style: none;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    gap: 5px;
    text-wrap: nowrap;
    font-family: Inter;
    font-size: 0.88rem;
    font-weight: 700;
    text-align: left;
    cursor: pointer;
    color: black;
}

.navbarContainer .link .link-list .nav .caretDown {
    display: grid;
    place-items: center;
    font-size: 24px;
}

.navbarContainer .link .link-list .nav .product {
    position: relative;
}

.navbarContainer .link .link-list .nav .product p {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.navbarContainer .link .link-list .nav .product p span {
    font-size: 2rem;
}

.navbarContainer .link .applyButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    width: 19rem;
    height: 46.39px;
    top: 8px;
    left: 1042px;
    padding: 12.79px 19.19px 12.8px 19.19px;
    gap: 0px;
    opacity: 1;
    background: #000000;
    color: white;
    font-weight: 700;
}

.navbarContainer .link .applyButton img {
    width: 40px;
    height: 16px;
    top: 15.18px;
    left: 260.81px;
    padding: 5.01px 0.47px 5.44px 0.64px;
    gap: 0px;
    opacity: 1;
}





/* Media Queries */
@media (min-width: 100px) and (max-width: 400px) {
    .navbarContainer {
        height: 31px;
        padding: 3rem 14rem !important;
    }



    .navbarContainer .link .link-list .nav {
        height: 30px;
        font-size: 1.4rem;
        gap: 1px;
    }

    .navbarContainer .link .link-list .nav .product p .caretDown {
        font-size: 3rem;
    }

    .navbarContainer .link {
        gap: 10px;
    }

    .navbarContainer .link .link-list {
        gap: 5px;
    }

    .navbarContainer .link .applyButton {
        width: 62px;
        height: 15px;
        padding: 5px;
        font-size: 1rem;
    }

    .navbarContainer .link .applyButton img {
        width: 11px;
        height: 12px;
    }
}

@media (min-width: 400px) and (max-width: 600px) {
    .navbarContainer {
        height: 28px;
        padding: 3rem 16rem !important;
    }



    .navbarContainer .link .link-list .nav {
        height: 30px;
        font-size: 1.2rem;
        gap: 3px;
    }

    .navbarContainer .link .link-list .nav .product p .caretDown {
        font-size: 3rem;
    }

    .navbarContainer .link {
        gap: 10px;
    }

    .navbarContainer .link .link-list {
        gap: 10px;
    }

    .navbarContainer .link .applyButton {
        width: 100px;
        height: 20px;
        padding: 5px;
    }

    .navbarContainer .link .applyButton img {
        width: 15px;
        height: 13px;
    }
}

@media (min-width: 600px) and (max-width: 700px) {
    .navbarContainer {
        height: 50px;
        padding: 3rem 0rem;
    }



    .navbarContainer .link .link-list .nav {
        height: 30px;
        font-size: 1.2rem;
        gap: 3px;
    }

    .navbarContainer .link {
        gap: 20px;
    }

    .navbarContainer .link .link-list {
        gap: 20px;
    }

    .navbarContainer .link .applyButton {
        width: 18.75rem;
        height: 26px;
        padding: 10px;
    }

    .navbarContainer .link .applyButton img {
        width: 18px;
    }
}

@media (min-width: 700px) and (max-width: 800px) {
    .navbarContainer {
        height: 50px;
        padding: 3rem 0rem;
    }



    .navbarContainer .link .link-list .nav {
        height: 30px;
        font-size: 1.2rem;
        gap: 3px;
    }

    .navbarContainer .link {
        gap: 20px;
    }

    .navbarContainer .link .link-list {
        gap: 20px;
    }

    .navbarContainer .link .applyButton {
        width: 18.75rem;
        height: 26px;
        padding: 10px;
    }

    .navbarContainer .link .applyButton img {
        width: 18px;
    }
}

@media (min-width: 800px) and (max-width: 1000px) {
    .navbarContainer {
        height: 52px;
        padding: 3rem 0rem;
    }



    .navbarContainer .link .link-list .nav {
        height: 30px;
        font-size: 1.5rem;
        gap: 3px;
    }

    .navbarContainer .link {
        gap: 20px;
    }

    .navbarContainer .link .link-list {
        gap: 20px;
    }

    .navbarContainer .link .applyButton {
        width: 23.625rem;
        height: 40px;
        padding: 10px;
    }

    .navbarContainer .link .applyButton p {
        font-size: 1.2rem;
    }

    .navbarContainer .link .applyButton img {
        width: 40px;
    }
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .navbarContainer {
        height: 60px;
        padding: 3rem 0;
    }



    .navbarContainer .link .link-list .nav {
        height: 30px;
        font-size: 1.2rem;
        gap: 3px;
    }

    .navbarContainer .link .link-list .nav .caretDown {
        font-size: 22px;
    }

    .navbarContainer .link {
        gap: 20px;
    }

    .navbarContainer .link .link-list {
        gap: 20px;
    }

    .navbarContainer .link .applyButton {
        width: 270px;
        height: 40px;
        padding: 10px;
    }

    .navbarContainer .link .applyButton img {
        width: 40px;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .navbarContainer {
        height: 60px;
        padding: 3rem 0;
    }



    .navbarContainer .link .link-list .nav {
        height: 30px;
        font-size: 1.2rem;
        gap: 3px;
    }

    .navbarContainer .link .link-list .nav .caretDown {
        font-size: 22px;
    }

    .navbarContainer .link {
        gap: 20px;
    }

    .navbarContainer .link .link-list {
        gap: 20px;
    }

    .navbarContainer .link .applyButton {
        width: 270px;
        height: 40px;
        padding: 10px;
    }

    .navbarContainer .link .applyButton img {
        width: 40px;
    }
}

@media (min-width: 1400px) and (max-width: 1700px) {
    .navbarContainer {
        height: 60px;
        padding: 3rem 0;
    }



    .navbarContainer .link .link-list .nav {
        height: 30px;
        font-size: 1.2rem;
        gap: 3px;
    }

    .navbarContainer .link .link-list .nav .caretDown {
        font-size: 22px;
    }

    .navbarContainer .link {
        gap: 20px;
    }

    .navbarContainer .link .link-list {
        gap: 20px;
    }

    .navbarContainer .link .applyButton {
        width: 270px;
        height: 40px;
        padding: 10px;
    }

    .navbarContainer .link .applyButton img {
        width: 40px;
    }
}