.topSection {
    width: 100%;
    height: 65.7rem;
    background: linear-gradient(180deg, rgba(255, 148, 78, 0.2) 3%, rgba(255, 166, 131, 0.3) 100%);
    position: relative;
    overflow: hidden;
}

.topSection .contactUsNavbar {
    padding: 1rem;
}

.topSection .contactUsNavbar .navbarContainer {
    padding: 1rem 13rem;
}

.topSection .topSectionHeading {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
    white-space: nowrap;

}

.topSection .topSectionHeading .topSectionHeading-h1 {
    font-family: Inter;
    font-size: 4rem;
    font-weight: 700;
}

.topSection .topSectionHeading .logo {
    width: 100%;
    height: 12.375rem;
    font-size: 12rem;
    display: flex;
    align-items: center;


}

.topSection .topSectionHeading .logo .logoLastWord {
    font-size: 5.5rem;
    bottom: 0%;
    left: 32%;



}

.topSection .topSectionHeading .topSectionHeading-p {
    font-family: Inter;
    font-size: 2.5rem;
    font-weight: 300;
}

.topSection .topSectionHeading .FinancialServices {
    font-size: 2rem;
    margin-top: -1.25rem;

}

.topSection .topSectionImage {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 51.29rem;
}

.topSection .topSectionImage img {
    width: 100%;
    height: 818.64px;
}

/* Media Queries */
@media (min-width: 100px) and (max-width: 400px) {
    .topSection .topSectionImage img {
        height: 164.64px;
    }

    .topSection .topSectionHeading img {
        height: 50px;
    }
}

@media (min-width: 400px) and (max-width: 500px) {
    .topSection .topSectionImage img {
        height: 205.64px;
    }

    .topSection .topSectionHeading img {
        height: 60px;
    }
}

@media (min-width: 500px) and (max-width: 700px) {
    .topSection .topSectionImage img {
        height: 245.64px;
    }

    .topSection .topSectionHeading img {
        height: 60px;
    }
}

@media (min-width: 700px) and (max-width: 800px) {
    .topSection .topSectionImage img {
        height: 328.64px;
    }

    .topSection .topSectionHeading img {
        height: 97px;
    }
}

@media (min-width: 800px) and (max-width: 900px) {
    .topSection .topSectionImage img {
        height: 368.64px;
    }

    .topSection .topSectionHeading img {
        height: 101px;
    }
}

@media (min-width: 900px) and (max-width: 1000px) {
    .topSection .topSectionImage img {
        height: 409.64px;
    }

    .topSection .topSectionHeading img {
        height: 101px;
    }
}

@media (min-width: 1000px) and (max-width: 1100px) {
    .topSection .topSectionImage img {
        height: 451.64px;
    }

    .topSection .topSectionHeading img {
        height: 115px;
    }
}

@media (min-width: 1100px) and (max-width: 1200px) {
    .topSection .topSectionImage img {
        height: 492.64px;
    }

    .topSection .topSectionHeading img {
        height: 136px;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .topSection .topSectionImage img {
        height: 533.64px;
    }

    .topSection .topSectionHeading img {
        height: 129px;
    }
}

@media (min-width: 1400px) and (max-width: 1500px) {
    .topSection .topSectionImage img {
        height: 574.64px;
    }

    .topSection .topSectionHeading img {
        height: 163px;
    }
}

@media (min-width: 1500px) and (max-width: 1600px) {
    .topSection .topSectionImage img {
        height: 615.64px;
    }
}

@media (min-width: 1600px) and (max-width: 1700px) {
    .topSection .topSectionImage img {
        height: 656.64px;
    }
}

@media (min-width: 1700px) and (max-width: 1800px) {
    .topSection .topSectionImage img {
        height: 697.64px;
    }
}

@media (min-width: 1800px) and (max-width: 2000px) {
    .topSection .topSectionImage img {
        height: 738.64px;
    }
}