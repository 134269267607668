.formContainer {
    width: 90%;
    height: 38rem;
    margin-top: 5%;
    display: grid;
    place-items: center;
}

.formContainer .formContent {
    width: 100%;
    height: 100%;
    box-shadow: 1rem 0.9rem 0.9rem 0px rgba(255, 159, 96, 1);
    border: 0.4px solid rgba(0, 0, 0, 1);
    border-radius: 3.8rem;
    padding: 4.8rem;
    display: flex;
    flex-direction: column;
    gap: 2.8rem;
}

.formContainer .formContent .formContent-header-p {
    font-family: Inter;
    font-size: 2.25rem; /* 36px */
    font-weight: 300;
}

.formContainer .formContent .form {
    width: 100%;
    height: 80%;
    display: flex;
    gap: 8rem;
}

.formContainer .formContent .form .form-header {
    width: 20rem;
    font-family: Inter;
    font-size: 3rem;
    font-weight: 700;
    line-height: 5rem;
}

.formContainer .formContent .form .formInput-list {
    width: 62%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
    overflow: hidden;
}

.formContainer .formContent .form .formInput-list .input {
    height: 3.125rem;
    border: none;
    outline: none;
    border-bottom: 0.2rem solid rgba(217, 217, 217, 1);
    font-family: Open Sans;
    font-size: 2.5rem;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.formContainer .formContent .form .formInput-list .input::placeholder {
    color: black;
}

.formContainer .formContent .form .formInput-list .submitButton {
    width: 17.5rem;
    height: 5.4375rem;
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 1);
    box-shadow: 1rem 0.8rem 0.5rem 0px rgba(255, 159, 96, 1);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 1rem;
}

.formContainer .formContent .form .formInput-list .submitButton .span {
    font-family: Inter;
    font-size: 1.7rem;
    font-weight: 500;
}

.formContainer .formContent .form .formInput-list .submitButton .arrow {
    font-size: 2.5rem;
}

@media (min-width: 100px) and (max-width: 400px) {
    .formContainer {
        height: 90rem;
    }

    .formContainer .formContent {
        align-items: center;
    }

    .formContainer .formContent .form {
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
        height: 100%;
    }

    .formContainer .formContent .form .form-header {
        width: 100%;
        text-align: center;
        text-wrap: balance;
    }

    .formContainer .formContent .form .formInput-list {
        width: 100%;
        grid-template-columns: repeat(1, 100%);
    }

    .formContainer .formContent .form .formInput-list .input {
        width: 100%;
        height: 7rem;
        font-size: 3.5rem;
    }

    .formContainer .formContent .form .formInput-list .submitButton {
        width: 17.5rem;
        height: 6.4375rem;
    }
}

@media (min-width: 400px) and (max-width: 600px) {
    .formContainer {
        height: 100rem;
    }

    .formContainer .formContent {
        align-items: center;
    }

    .formContainer .formContent .form {
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
        height: 100%;
    }

    .formContainer .formContent .form .form-header {
        width: 100%;
        text-align: center;
        text-wrap: balance;
    }

    .formContainer .formContent .form .formInput-list {
        width: 100%;
        grid-template-columns: repeat(1, 100%);
    }

    .formContainer .formContent .form .formInput-list .input {
        width: 100%;
        height: 7rem;
        font-size: 3.2rem;
    }

    .formContainer .formContent .form .formInput-list .submitButton {
        width: 17.5rem;
        height: 6.4375rem;
    }
}

@media (min-width: 600px) and (max-width: 700px) {
    .formContainer {
        height: 42rem;
    }

    .formContainer .formContent {
        padding: 6rem;
    }

    .formContainer .formContent .form {
        gap: 5rem;
        height: 100%;
    }

    .formContainer .formContent .form .formInput-list {
        width: 52%;
        gap: 2.4rem;
    }

    .formContainer .formContent .form .formInput-list .input {
        font-size: 1.9rem;
    }

    .formContainer .formContent .form .formInput-list .submitButton {
        width: 13.5rem;
        height: 5.2375rem;
    }
}

@media (min-width: 700px) and (max-width: 800px) {
    .formContainer {
        height: 44rem;
    }

    .formContainer .formContent {
        padding: 8rem;
    }

    .formContainer .formContent .form {
        gap: 12rem;
        height: 100%;
    }

    .formContainer .formContent .form .formInput-list {
        width: 50%;
    }

    .formContainer .formContent .form .formInput-list .input {
        font-size: 1.9rem;
    }

    .formContainer .formContent .form .formInput-list .submitButton {
        width: 14.5rem;
        height: 5.4375rem;
    }
}

@media (min-width: 800px) and (max-width: 1100px) {
    .formContainer {
        height: 44rem;
    }

    .formContainer .formContent {
        padding: 8rem;
    }

    .formContainer .formContent .form {
        gap: 12rem;
        height: 100%;
    }

    .formContainer .formContent .form .formInput-list {
        width: 50%;
    }

    .formContainer .formContent .form .formInput-list .input {
        font-size: 2rem;
    }

    .formContainer .formContent .form .formInput-list .submitButton {
        width: 14.5rem;
        height: 5.4375rem;
    }
}

@media (min-width: 1100px) and (max-width: 1200px) {
    .formContainer {
        height: 35rem;
    }

    .formContainer .formContent .form {
        gap: 8rem;
        height: 100%;
    }

    .formContainer .formContent .form .formInput-list {
        width: 50%;
    }

    .formContainer .formContent .form .formInput-list .input {
        font-size: 2.1rem;
    }

    .formContainer .formContent .form .formInput-list .submitButton {
        width: 13.35rem;
        height: 4.4375rem;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .formContainer {
        height: 38rem;
    }

    .formContainer .formContent {
        padding: 6.8rem;
    }

    .formContainer .formContent .form {
        justify-content: unset;
        gap: 6rem;
        height: 100%;
    }

    .formContainer .formContent .form .formInput-list {
        width: 60%;
    }

    .formContainer .formContent .form .formInput-list .input {
        font-size: 2.1rem;
    }

    .formContainer .formContent .form .formInput-list .submitButton {
        width: 13.5rem;
        height: 4.4375rem;
    }
}

@media (min-width: 1400px) and (max-width: 1700px) {
    .formContainer {
        height: 38rem;
    }

    .formContainer .formContent {
        padding: 6.8rem;
    }

    .formContainer .formContent .form {
        justify-content: unset;
        gap: 6rem;
        height: 100%;
    }

    .formContainer .formContent .form .formInput-list {
        width: 60%;
    }

    .formContainer .formContent .form .formInput-list .input {
        font-size: 2.1rem;
    }

    .formContainer .formContent .form .formInput-list .submitButton {
        width: 13.5rem;
        height: 4.4375rem;
    }
}
