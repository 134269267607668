.dropDown {
    position: absolute;
    width: 38.125rem;
    height: 24.5rem;
    top: 120%;
    left: 0%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    border-radius: 0.8rem;
    font-weight: 400;
    z-index: 5;
    transition: 0.3s all ease-in-out;
}

.dropDown .unsecuredCapital {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}

.dropDown .unsecuredCapital p {
    height: 20%;
    font-family: Inter;
    font-size: 1.30875rem;
    font-weight: 500;
    color: rgba(125, 126, 121, 1);
}

.dropDown .unsecuredCapital .capitalNames {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.8rem;
}

.dropDown .unsecuredCapital .capitalNames div {
    display: flex;
    gap: 10px;
    align-items: center;
}

.dropDown .unsecuredCapital .capitalNames div .icon {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background: rgba(255, 158, 94, 0.25);
}

.dropDown .unsecuredCapital .capitalNames div .icon .iconImage {
    width: 2.80rem;
    height: 2.80rem;
    display: grid;
    place-items: center;
}

.dropDown .unsecuredCapital .capitalNames div .icon .iconImage img {
    width: auto;
    height: 1.80rem;
}

.dropDown .unsecuredCapital .capitalNames div p {
    font-family: Inter;
    font-size: 1.39625rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
}

.dropDown .unsecuredCapital .capitalNames div p:hover {
    color: #FF9F60;
}

.dropDown .unsecuredCapital .capitalNames div:nth-child(2) .icon {
    background-color: transparent;
}

.dropDown .unsecuredCapital .capitalNames div:nth-child(2) .icon img {
    width: 100%;
    height: 100%;
}

.dropDown .securedCapital {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}

.dropDown .securedCapital p {
    height: 20%;
    font-family: Inter;
    font-size: 1.30875rem;
    font-weight: 500;
    color: rgba(125, 126, 121, 1);
}

.dropDown .securedCapital .capitalNames {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.8rem;
}

.dropDown .securedCapital .capitalNames div {
    display: flex;
    gap: 10px;
    align-items: center;
}

.dropDown .securedCapital .capitalNames div .icon {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background: rgba(255, 158, 94, 0.25);
}

.dropDown .securedCapital .capitalNames div .icon .iconImage {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.dropDown .securedCapital .capitalNames div .icon .iconImage img {
    width: auto;
    height: 2rem;
}

.dropDown .securedCapital .capitalNames div p {
    font-family: Inter;
    font-size: 1.39625rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
}

.dropDown .securedCapital .capitalNames div p:hover {
    color: #FF9F60;
}

@media (min-width: 100px) and (max-width: 400px) {
    .dropDown {
        width: 53.125rem;
        height: 28.5rem;
        padding: 4rem;
        left: -1rem;
    }

    .dropDown .unsecuredCapital p,
    .dropDown .securedCapital p {
        font-size: 2rem;
    }

    .dropDown .unsecuredCapital .capitalNames div .icon,
    .dropDown .securedCapital .capitalNames div .icon {
        width: 3rem;
        height: 3rem;
    }

    .dropDown .unsecuredCapital .capitalNames div p,
    .dropDown .securedCapital .capitalNames div p {
        font-size: 1.69625rem;
    }
}

@media (min-width: 400px) and (max-width: 500px) {
    .dropDown {
        width: 57.125rem;
        height: 28.5rem;
        padding: 4rem;
    }

    .dropDown .unsecuredCapital p,
    .dropDown .securedCapital p {
        font-size: 2.2rem;
    }

    .dropDown .unsecuredCapital .capitalNames div .icon,
    .dropDown .securedCapital .capitalNames div .icon {
        width: 3rem;
        height: 3rem;
    }

    .dropDown .unsecuredCapital .capitalNames div p,
    .dropDown .securedCapital .capitalNames div p {
        font-size: 1.99625rem;
    }
}

@media (min-width: 500px) and (max-width: 600px) {
    .dropDown {
        width: 51.125rem;
        height: 28.5rem;
        padding: 4rem;
    }

    .dropDown .unsecuredCapital p,
    .dropDown .securedCapital p {
        font-size: 2rem;
    }

    .dropDown .unsecuredCapital .capitalNames div .icon,
    .dropDown .securedCapital .capitalNames div .icon {
        width: 3rem;
        height: 3rem;
    }

    .dropDown .unsecuredCapital .capitalNames div p,
    .dropDown .securedCapital .capitalNames div p {
        font-size: 1.69625rem;
    }
}

@media (min-width: 600px) and (max-width: 800px) {
    .dropDown {
        width: 53.125rem;
        height: 30.5rem;
    }

    .dropDown .unsecuredCapital p,
    .dropDown .securedCapital p {
        font-size: 2.0875rem;
    }

    .dropDown .unsecuredCapital .capitalNames div .icon,
    .dropDown .securedCapital .capitalNames div .icon {
        width: 3rem;
        height: 3rem;
    }

    .dropDown .unsecuredCapital .capitalNames div p,
    .dropDown .securedCapital .capitalNames div p {
        font-size: 2.09625rem;
    }
}

@media (min-width: 800px) and (max-width: 1100px) {
    .dropDown {
        width: 60.125rem;
        height: 30.5rem;
        padding: 4rem;
    }

    .dropDown .unsecuredCapital p,
    .dropDown .securedCapital p {
        font-size: 2.29625rem;
    }

    .dropDown .unsecuredCapital .capitalNames div .icon,
    .dropDown .securedCapital .capitalNames div .icon {
        width: 3rem;
        height: 3rem;
    }

    .dropDown .unsecuredCapital .capitalNames div p,
    .dropDown .securedCapital .capitalNames div p {
        font-size: 2.19625rem;
    }
}

@media (min-width: 1100px) and (max-width: 1200px) {
    .dropDown {
        width: 57.125rem;
        height: 28.5rem;
        padding: 4rem;
    }

    .dropDown .unsecuredCapital p,
    .dropDown .securedCapital p {
        font-size: 2.19625rem;
    }

    .dropDown .unsecuredCapital .capitalNames div .icon,
    .dropDown .securedCapital .capitalNames div .icon {
        width: 3rem;
        height: 3rem;
    }

    .dropDown .unsecuredCapital .capitalNames div p,
    .dropDown .securedCapital .capitalNames div p {
        font-size: 2.09625rem;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .dropDown {
        width: 53.125rem;
        height: 26.5rem;
        padding: 3rem;
    }

    .dropDown .unsecuredCapital p,
    .dropDown .securedCapital p {
        font-size: 1.7625rem;
    }

    .dropDown .unsecuredCapital .capitalNames div .icon,
    .dropDown .securedCapital .capitalNames div .icon {
        width: 3rem;
        height: 3rem;
    }

    .dropDown .unsecuredCapital .capitalNames div p,
    .dropDown .securedCapital .capitalNames div p {
        font-size: 1.625rem;
    }
}

@media (min-width: 1400px) and (max-width: 1700px) {
    .dropDown {
        width: 52.125rem;
        height: 26.5rem;
        padding: 3rem;
    }

    .dropDown .unsecuredCapital p,
    .dropDown .securedCapital p {
        font-size: 1.7625rem;
    }

    .dropDown .unsecuredCapital .capitalNames div .icon,
    .dropDown .securedCapital .capitalNames div .icon {
        width: 3rem;
        height: 3rem;
    }

    .dropDown .unsecuredCapital .capitalNames div p,
    .dropDown .securedCapital .capitalNames div p {
        font-size: 1.625rem;
    }
}
