.financingSolutionsContainer {
    width: 80%;
}

.financingSolutionsContainer .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem; /* 20px */
    margin-top: 5%;
}

.financingSolutionsContainer .container .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.25rem; /* 20px */
}

.financingSolutionsContainer .container .buttons .getStarted {
    width: 12.505625rem; /* 200.09px */
    height: 2.899375rem; /* 46.39px */
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    background: rgba(255, 159, 96, 1);
    padding: 0.799375rem 1.19875rem; /* 12.79px 19.18px */
    text-wrap: wrap;
    cursor: pointer;
}

.financingSolutionsContainer .container .buttons .getStarted .started {
    text-wrap: nowrap;
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8rem;
}

.financingSolutionsContainer .container .buttons .getStarted img {
    width: 3rem;
    height: 0.9rem;
}

.financingSolutionsContainer .container .buttons .applyButton {
    width: 15.75rem; /* 252px */
    height: 3.024375rem; /* 48.39px */
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    border: 1px solid rgba(255, 159, 96, 1);
    padding: 0.799375rem 1.19875rem; /* 12.79px 19.18px */
    text-wrap: wrap;
    cursor: pointer;
}

.financingSolutionsContainer .container .buttons .applyButton .apply {
    text-wrap: wrap;
    color: rgba(255, 159, 96, 1);
    cursor: pointer;
    text-transform: uppercase;
    border: none;
    background-color: transparent;
    outline: none;
    font-family: Open Sans;
    font-size: 1rem; /* 16px */
    font-weight: 700;
}

.financingSolutionsContainer .container .buttons .applyButton img {
    width: 2rem;
    height: 0.5rem;
}

.financingSolutionsContainer .container .head {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.625rem;
    font-size: 1rem; /* 10px */
}

.financingSolutionsContainer .container .tabs {
    width: 100%;
    height: 3.025rem;
    border: 1px solid rgba(220, 220, 220, 1);
    background: rgba(249, 249, 249, 1);
    border-radius: 1.875rem; /* 30px */
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding: 0.125rem;
    position: relative;
}

.financingSolutionsContainer .container .tabs .tab {
    height: 100%;
    padding: 0.625rem; /* 10px */
    border-radius: 2.5rem;
    font-size: 1rem;
    text-wrap: nowrap; /* 40px */
    border: 1px 0px 0px 0px;
    opacity: 1;
    cursor: pointer;
    color: white;
    border: 1px solid rgba(0, 0, 0, 1);
    transition: 0.2s all ease;
    overflow: hidden;
}

.financingSolutionsContainer .container .content1 {
    width: 100%;
    border-radius: 0.5rem; /* 8px */
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(220, 220, 220, 1);
    overflow: hidden;
    padding: 1rem;
}

.financingSolutionsContainer .container .content1 .column {
    width: 100%;
    display: flex;
    gap: 1.875rem; /* 30px */
    padding: 0.625rem; /* 10px */
}

.financingSolutionsContainer .container .content1 .column .icon {
    width: 4rem;
    height: 4rem;
}

.financingSolutionsContainer .container .content1 .column .icon img {
    width: auto;
    height: 64px;
}

.financingSolutionsContainer .container .content1 .column .columnContent {
    display: flex;
    flex-direction: column;
    gap: 0.625rem; /* 10px */
}

.financingSolutionsContainer .container .content1 .column .columnContent .columnContentHeader {
    font-family: Inter;
    font-size: 1.93rem; /* 30.88px */
    font-weight: 700;
}

.financingSolutionsContainer .container .content1 .column .columnContent .columnContentHeader-content {
    display: flex;
    flex-direction: column;
}

.financingSolutionsContainer .container .content1 .column .columnContent .columnContentHeader-content .columnContentHeader-content-item {
    font-family: Open Sans;
    font-size: 0.95rem; /* 15.2px */
    font-weight: 400;
}

/* Media Queries */
@media (min-width: 100px) and (max-width: 500px) {
    .financingSolutionsContainer .container {
        padding: 1rem;
    }

    .financingSolutionsContainer .container .tabs {
        justify-content: unset;
    }

    .financingSolutionsContainer .container .tabs .tab {
        padding: 0.1rem;
        font-size: 0.8rem;
    }

    .financingSolutionsContainer .container .content1 .column .icon img {
        height: 14px;
    }
}

@media (min-width: 500px) and (max-width: 800px) {
    .financingSolutionsContainer .container .content1 .column .icon img {
        height: 24px;
    }
}

@media (min-width: 800px) and (max-width: 1000px) {
    .financingSolutionsContainer .container .content1 .column .icon img {
        height: 34px;
    }
}

@media (min-width: 1000px) and (max-width: 1400px) {
    .financingSolutionsContainer .container .content1 .column .icon img {
        height: 44px;
    }
}

@media (min-width: 1400px) and (max-width: 2000px) {
    .financingSolutionsContainer .container .content1 .column .icon img {
        height: 54px;
    }
}
