.contactUsContainer {
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.contactUsContainer::-webkit-scrollbar {
    display: none;
}

.contactUsContainer .containerContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.contactUsContainer .containerContent .officeSection {
    width: 90%;
    display: flex;
}

.contactUsContainer .containerContent .officeSection .row {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    margin-top: 10%;
}

.contactUsContainer .containerContent .officeSection .row .officeAbout {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 11rem;
}

.contactUsContainer .containerContent .officeSection .row .officeAbout .leftContent {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 6.25rem;
}

.contactUsContainer .containerContent .officeSection .row .officeAbout .leftContent .leftContent-header-h3 {
    font-family: Inter;
    font-size: 5.5rem;
    font-weight: 700;
}

.contactUsContainer .containerContent .officeSection .row .officeAbout .leftContent .leftContent-header-p {
    font-family: Open Sans;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 3.125rem;
}

.contactUsContainer .containerContent .officeSection .row .officeAbout .leftContent .address {
    font-family: Open Sans;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 3.125rem;
}

.contactUsContainer .containerContent .officeSection .row .officeAbout .officeImage {
    width: 56.0625rem;
    height: 31.8125rem;
}

.contactUsContainer .containerContent .officeSection .row .officeAbout .officeImage img {
    width: auto;
    height: 509px;
}

.contactUsContainer .containerContent .officeSection .row .contactDetails {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    margin-top: 5rem;
}

.contactUsContainer .containerContent .officeSection .row .contactDetails .contactDetails-header-h3 {
    font-family: Inter;
    font-size: 2.5rem;
    font-weight: 700;
}

.contactUsContainer .containerContent .officeSection .row .contactDetails .details {
    width: 100%;
}

.contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .detailTitle {
    font-family: Inter;
    font-size: 2rem;
    font-weight: 300;
    text-decoration: none;
}

.contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .contactLink {
    font-family: Inter;
    font-size: 2rem;
    font-weight: 500;
    text-decoration: none;
    color: black;
}

.contactUsContainer .containerContent .formSection {
    width: 100%;
    height: 60rem;
    display: grid;
    place-items: center;
}

/* Media Queries */
@media (min-width: 100px) and (max-width: 400px) {
    .contactUsContainer .containerContent .formSection {
        width: 100%;
        height: 115rem;
        padding: 0.8rem;
    }

    .contactUsContainer .containerContent .officeSection .row {
        gap: 3.125rem;
    }

    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list {
        gap: 1.4rem;
    }

    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .phoneNumber,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .forSales,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .forEnquiries,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .forSupport {
        font-size: 1.5rem;
    }

    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .vCareEmail,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .phoneNumber-1,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .phoneNumber-2 {
        font-size: 1.2rem;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout {
        flex-direction: column;
        gap: 1.4rem;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout .leftContent {
        width: 100%;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout .officeImage {
        width: auto;
        height: 33.375rem;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout .officeImage img {
        height: 34.8125rem;
    }
}

@media (min-width: 400px) and (max-width: 600px) {
    .contactUsContainer .containerContent .formSection {
        width: 100%;
        height: 120rem;
        padding: 10px;
    }

    .contactUsContainer .containerContent .officeSection .row {
        gap: 50px;
    }

    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .phoneNumber,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .forSales,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .forEnquiries,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .forSupport {
        font-size: 2rem;
    }

    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .vCareEmail,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .phoneNumber-1,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .phoneNumber-2 {
        font-size: 1.5rem;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout {
        flex-direction: column;
        gap: 20px;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout .leftContent {
        width: 100%;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout .officeImage {
        width: 70%;
        height: 35.375rem;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout .officeImage img {
        height: 35.8125rem;
    }
}

@media (min-width: 600px) and (max-width: 800px) {
    .contactUsContainer .containerContent .formSection {
        width: 100%;
        height: 80rem;
        padding: 10px;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout .officeImage img {
        width: auto;
        height: 201px;
    }

    .contactUsContainer .containerContent .officeSection .row {
        gap: 50px;
    }

    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .phoneNumber,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .forSales,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .forEnquiries,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .forSupport {
        font-size: 2rem;
    }

    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .vCareEmail,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .phoneNumber-1,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .phoneNumber-2 {
        font-size: 1.5rem;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout {
        flex-direction: column;
        gap: 1.4rem;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout .leftContent {
        width: 100%;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout .officeImage {
        width: 60%;
        height: 200px;
    }
}

@media (min-width: 800px) and (max-width: 1000px) {
    .contactUsContainer .containerContent .formSection {
        width: 100%;
        height: 68rem;
        padding: 10px;
    }

    .contactUsContainer .containerContent .officeSection .row {
        gap: 50px;
    }

    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .phoneNumber,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .forSales,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .forEnquiries,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .forSupport {
        font-size: 2rem;
    }

    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .vCareEmail,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .phoneNumber-1,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .phoneNumber-2 {
        font-size: 1.5rem;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout {
        flex-direction: column;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout .leftContent {
        width: 100%;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout .officeImage {
        width: 50%;
        height: 250px;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout .officeImage img {
        width: auto;
        height: 251px;
    }
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .contactUsContainer .containerContent .formSection {
        width: 100%;
        height: 60rem;
        padding: 10px;
    }

    .contactUsContainer .containerContent .officeSection .row {
        gap: 50px;
    }

    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .phoneNumber,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .forSales,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .forEnquiries,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .forSupport {
        font-size: 2rem;
    }

    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .vCareEmail,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .phoneNumber-1,
    .contactUsContainer .containerContent .officeSection .row .contactDetails .details .details-list .details-list-item .phoneNumber-2 {
        font-size: 1.5rem;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout {
        flex-direction: column;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout .leftContent {
        width: 100%;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout .officeImage {
        width: 50%;
        height: 250px;
    }

    .contactUsContainer .containerContent .officeSection .row .officeAbout .officeImage img {
        width: auto;
        height: 301px;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .contactUsContainer .containerContent .officeSection .row .officeAbout .officeImage img {
        width: auto;
        height: 437px;
    }
}

@media (min-width: 1400px) and (max-width: 1500px) {
    .contactUsContainer .containerContent .officeSection .row .officeAbout .officeImage img {
        width: auto;
        height: 467px;
    }
}

@media (min-width: 1500px) and (max-width: 1600px) {
    .contactUsContainer .containerContent .officeSection .row .officeAbout .officeImage img {
        width: auto;
        height: 467px;
    }
}
