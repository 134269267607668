.required-documents {
    width: 90%;
    height: 42rem;
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-color: white;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 11rem;
}

.required-documents .required-documents-head-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.required-documents .required-documents-head-content .required-documents-header {
    font-family: Inter;
    font-size: 5rem;
    font-weight: 700;
}

.required-documents .required-documents-head-content .required-documents-description {
    font-family: Open Sans;
    font-size: 1.1rem;
    font-weight: 400;
}

.required-documents .required-documents-cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.3rem;
}

.required-documents .required-documents-cards .document-card {
    background: rgba(255, 158, 94, 0.18);
    border: 1.46px solid rgba(153, 223, 235, 1);
    width: 22.5rem;
    height: 17.125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 1.0625rem;
    overflow: hidden;
    padding: 3rem 3rem 0 3rem;
}

.required-documents .required-documents-cards .document-card .document-card-title {
    margin-top: 10%;
    font-family: Inter;
    font-size: 1.8rem;
    font-weight: 700;
}

.required-documents .required-documents-cards .document-card .document-card-image {
    width: auto;
    height: 50%;
}

.required-documents .required-documents-cards .document-card .document-card-image img {
    width: auto;
    height: 112px;
}

/* Media Queries */
@media (min-width: 100px) and (max-width: 450px) {
    .required-documents {
        height: 111rem;
        margin-top: 22rem;
    }

    .required-documents .required-documents-head-content .required-documents-header {
        font-size: 8rem;
    }

    .required-documents .required-documents-head-content .required-documents-description {
        font-size: 3.1rem;
    }

    .required-documents .required-documents-cards {
        grid-template-columns: repeat(2, 20rem);
        gap: 15rem;
    }

    .required-documents .required-documents-cards .document-card {
        border: 1px solid rgba(153, 223, 235, 1);
    }
}

@media (min-width: 450px) and (max-width: 1000px) {
    .required-documents {
        height: 44rem;
        width: 100%;
        margin-top: 13rem;
    }

    .required-documents .required-documents-cards {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 100px) and (max-width: 400px) {
    .required-documents .required-documents-cards .document-card .document-card-image img {
        height: 22px;
    }
}

@media (min-width: 400px) and (max-width: 500px) {
    .required-documents .required-documents-cards .document-card .document-card-image img {
        height: 27px;
    }
}

@media (min-width: 500px) and (max-width: 700px) {
    .required-documents .required-documents-cards .document-card .document-card-image img {
        height: 33px;
    }
}

@media (min-width: 700px) and (max-width: 900px) {
    .required-documents .required-documents-cards .document-card .document-card-image img {
        height: 49px;
    }
}

@media (min-width: 900px) and (max-width: 1000px) {
    .required-documents .required-documents-cards .document-card .document-card-image img {
        height: 56px;
    }
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .required-documents .required-documents-cards .document-card .document-card-image img {
        height: 61px;
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .required-documents .required-documents-cards .document-card .document-card-image img {
        height: 78px;
    }
}

@media (min-width: 1500px) and (max-width: 1700px) {
    .required-documents .required-documents-cards .document-card .document-card-image img {
        height: 91px;
    }
}

@media (min-width: 1700px) and (max-width: 2000px) {
    .required-documents .required-documents-cards .document-card .document-card-image img {
        height: 101px;
    }
}
