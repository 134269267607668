.footer-container {
    width: 100%;
    height: 50rem;
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, rgba(255, 148, 78, 0.1) 3%, rgba(255, 166, 131, 0.1) 100%);
}

.footer-container .footer {
    width: 80%;
    height: 100%;
    display: flex;

    justify-content: center;
    gap: 30px;
}

.footer-container .footer .footer-column1 {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 50px;
    padding: 10px;
    margin-top: 15rem;


}

.footer-container .footer .footer-column1 .footer-row {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: 30px;
}

.footer-container .footer .footer-column1 .footer-row .footer-head {
    font-family: Inter;
    font-size: 20px;
    font-weight: 900;
    white-space: nowrap;
}

.footer-container .footer .footer-column1 .footer-row .footer-links {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footer-container .footer .footer-column1 .footer-row .footer-links a {
    text-decoration: none;
    color: black;
}

.footer-container .footer .footer-column1 .footer-row .footer-links .footer-link {
    font-family: Inter;
    font-size: 15.13px;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
}

.footer-container .footer .footer-column2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;
}

.footer-container .footer .footer-column2 .footer-content {
    gap: 20px;
    margin-right: 15%;
}

.footer-container .footer .footer-column2 .footer-content .footer-follow-us {
    font-family: Inter;
    font-size: 19.69px;
    font-weight: 700;
    align-items: flex-start;
}

.footer-container .footer .footer-column2 .footer-content .footer-social-icons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
}

@media (min-width: 100px) and (max-width: 1100px) {
    .footer-container {
        height: unset;
        padding: 20px;
    }

    .footer-container .footer .footer-column1 {
        flex-wrap: wrap;
        border-bottom: none;
        justify-content: unset;

    }

    .footer-container .footer .footer-column2 {
        display: none;
    }
}